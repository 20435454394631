import "./navbar.scss";
import * as React from 'react';
import { useContext } from "react";
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import styled from "styled-components";

import { DarkModeContext } from "../../context/darkModeContext";
import { VendorUserContext } from "../../context/VendorUserContext";
import { DrinklinkAdmin } from "../../context/VendorUserContext";

const VendorHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const VendorImage = styled.div`
  display: flex;
`

const VendorDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const VendorDetailsName = styled.div`
    font-size: 22px;
    font-weight: Bold;
`;

const VendorDetailsAddress = styled.div`
    font-size: 18px;
`

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const { userVendorSelected } = useContext(VendorUserContext);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div>
            <VendorHeaderWrapper>
                {userVendorSelected.vendorImageUrl && <VendorImage>
                    <img src={userVendorSelected.vendorImageUrl} style={{height: "75px"}} alt="vendor"/>
                </VendorImage>}
                <VendorDetails>
                    <VendorDetailsName>{userVendorSelected.vendorName}</VendorDetailsName>
                    <VendorDetailsAddress>{userVendorSelected.vendorCity}, {userVendorSelected.vendorState}</VendorDetailsAddress>
                </VendorDetails>
            </VendorHeaderWrapper>
        </div>
        <div className="items">
          <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div>
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <img
              src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;