import * as React from 'react';
import { useState, useContext } from 'react';
import {styled} from 'styled-components'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { MuiOtpInput } from 'mui-one-time-password-input'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

import { useNavigate } from 'react-router-dom';

import awsconfig from '../../../src/aws-exports';
import {Amplify} from 'aws-amplify';
import {Auth } from 'aws-amplify';
import TopNavBar from '../Landing/topNavBar';
import {Spa, SpaceBarRounded} from '@mui/icons-material';

Amplify.configure(awsconfig);

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 650px;
  margin-inline: auto;
`
const PasswordInstructionsWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    justify-content: center;
`

export const SignUp = () => {
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [number, setNumber] = useState();

    const navigate = useNavigate();

    const handleOtpChange = (newValue) => {
        setOtp(newValue);
    }

    const handleSendSignUpVerification = async (event) => {
        event.preventDefault();
        if (password !== repeatedPassword) {
            setError('Passwords do not match');
            return;
        }
        try {
            console.log('calling Auth.forgotPassword', number);
            const data = await Auth.signUp({
                username: number,
                password: password,
                autoSignIn: { enabled: true}
            })
            console.log('data', data)
            setEmailSent(true);
            setError(false);
        } catch (e) {
            console.log('problem signing Up', e);
            setError(e.message)
        }
    }

    const handleConfirmVerification = async (event) => {
        event.preventDefault();
        
        try {
            const resp = await Auth.confirmSignUp(number, otp);
            console.log('resp', resp);
            navigate('/login?newSignUp=true');
        } catch (e) {
            console.log('problem resetting password', e);
            setError(e.message)
        }
    }

    return (
        <>
            <TopNavBar />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                {!emailSent ? (
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign Up
                        </Typography>
                        {error && <Alert severity="error">{error}.</Alert>}
                        <Box component="form" onSubmit={handleSendSignUpVerification} noValidate sx={{ mt: 1 }}>
                            {/* <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                //autoComplete="email"
                                autoFocus
                                onChange={e=>setEmail(e.target.value)}
                            /> */}
                            <PhoneInput
                                addInternationalOption={false}
                                defaultCountry="US"
                                value={number}
                                onChange={setNumber}
                                placeholder="Enter Phone Number"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={e=>setPassword(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="repeatedPassword"
                                label="Repeat Password"
                                type="password"
                                id="repeatedPassword"
                                autoComplete="current-password"
                                onChange={e=>setRepeatedPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Register
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="/login" variant="body2">
                                        Back to Signin
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h2" variant="h5">
                            Enter Code from text message:
                        </Typography>
                        <Typography component="h2" variant="h5">
                            {email}
                        </Typography>
                        {error && <Alert severity="error">{error}.</Alert>}
                        <Box component="form" onSubmit={handleConfirmVerification} noValidate sx={{ mt: 1 }}>
                            <MuiOtpInputStyled value={otp} onChange={handleOtpChange} length="6"/>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Confirm Email
                            </Button>
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Back to Signin
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid> */}
                        </Box>
                    </Box>
                )}
            </Container>
        </>
    )
};

export default SignUp;