import {useState, useCallback, useEffect, useMemo} from 'react';

export const toOriginal = (str) => {
    return str.replace(/[^0-9]/g, '');
};

export const convertAndTrimNumber = (str) => {
    const number = str.replace(/[^0-9]/g, '');
    return number.startsWith('1') ? number.substr(1) : number;
}

export const postProcessing = (numStr) => {
    if (!numStr || numStr.includes('deleted')) {
        return numStr;
    }

    if (numStr.length < 2) {
        return numStr;
    }

    var processed = '';
    if (numStr[0] === '1') {
        processed = '1 ';
        numStr = numStr.substr(1);
    }

    processed += '(' + numStr.substr(0, 3);
    if (numStr.length > 3) {
        processed += ') ' + numStr.substr(3, 3);
        if (numStr.length > 6) {
            processed += '-' + numStr.substr(6, 4);
        }
    }
    return processed;
}

export const containsSimilarNumbers = (numbers, number) => {
    const phoneNumber = convertAndTrimNumber(number.number);
    let match = false;
    numbers.forEach((arrayNumber) => {
        const arrayPhoneNumber = convertAndTrimNumber(arrayNumber.number);
        if (phoneNumber === arrayPhoneNumber) {
            match = true;
        }
    });
    return match;
};

export const cleanPhoneNumber = (ph) => {
    let allNumbers = toOriginal(ph);
    if (allNumbers.length < 11)
        return `1${allNumbers}`;
    return allNumbers;
}

export const removeCountryCodeAndChars = (ph) => {
    let allNumbers = toOriginal(ph);
    if (allNumbers.length > 10 && allNumbers[0] === '1') {
        return allNumbers.slice(1);
    }
    return allNumbers;
}

export const usePhoneNumber = (str) => {
    const [phoneNumber, setPhoneNumber] = useState(str);
    const onNumberChange = useCallback((str) => {
        const origin = toOriginal(str);
        const processed = postProcessing(origin);
        setPhoneNumber(processed);
    }, []);

    useEffect(() => {
        const origin = toOriginal(str);
        const processed = postProcessing(origin);
        setPhoneNumber(processed);
    }, [str]);

    const isValidPhoneNumber = useMemo(() => {
        const origin = toOriginal(phoneNumber);

        return (
            origin.length > 0 &&
            (
                (origin[0] === '1' && origin.length === 11) ||
                (origin[0] !== '1' && origin.length === 10)
            )
        );
    }, [phoneNumber]);

    return {
        phoneNumber,
        onNumberChange,
        cleanPhoneNumber,
        isValidPhoneNumber,
        toOriginal,
        removeCountryCodeAndChars
    };
};

export default usePhoneNumber;