import * as React from 'react';
import Modal from '@mui/material/Modal';
import "./drinks-details-modal.scss";
import moment from 'moment';
import {NumericFormat} from 'react-number-format';

import {formatMoney} from '../../utils/utils';

const DrinkDetailsModal = ({modalState, theDrink, handleModalClose}) => {
   // const aDate = new Date("2022-01-29T23:00:00").getTime();
    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='drink-details-wrapper'>
                <div className='drink-details-heading'>
                    Drink Details
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Drink:
                    </div>
                    <div className='drink-detail-data'>
                        {theDrink.menuItemName}
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Description:
                    </div>
                    <div className='drink-detail-data'>
                        {theDrink.menuItemDescription}
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Cost:
                    </div>
                    <div className='drink-detail-data'>
                        <NumericFormat value={formatMoney(theDrink.drinkCost).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Tip:
                    </div>
                    <div className='drink-detail-data'>
                        <NumericFormat value={formatMoney(theDrink.tip).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        {theDrink.state === 'paid' ? 'Drinklink Paid' : 'Drinklink Owes'}
                    </div>
                    <div className='drink-detail-data'>
                        <NumericFormat value={formatMoney(theDrink.drinkCost + theDrink.tip).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Expires:
                    </div>
                    <div className='drink-detail-data'>
                        {theDrink.expire_data ? theDrink.expire_data : 'never'}
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Status:
                    </div>
                    <div className='drink-detail-data'>
                        <ul>
                            {
                                JSON.parse(theDrink.stateHistory).map((st, index) => <li key={index}>{st.drink_status}, {moment(st.date).format('MMM Do YYYY hh:mm')}</li>)
                            }
                        </ul>
                    </div>
                </div>
                <div className='drink-detail'>
                    <div className='drink-detail-title'>
                        Notes:
                    </div>
                    <div className='drink-detail-data'>
                    {theDrink.vendorNotes ? 
                        <ul>
                            {
                                JSON.parse(theDrink.vendorNotes).map(nt => <li>{nt.note}, {moment(nt.date).format('MMM Do YYYY hh:mm')}</li>)
                            }
                        </ul>
                      : 'none'
                    }
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DrinkDetailsModal;