import * as React from 'react';
import { useState } from "react";
import Modal from '@mui/material/Modal';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import {API, graphqlOperation} from "aws-amplify";
import 'react-phone-number-input/style.css';
import "./add-user-modal.scss";
import Select from 'react-select'
import {Alert} from '@mui/material';
import styled from 'styled-components';
import usePhoneNumber from '../../hooks/usePhoneNumber';
import { Input } from '@mui/material'

import {userRoles} from '../../utils/utils';
import {listUsers, userByPhone} from '../../graphql/queries';

const FindUserWrapper = styled.div`

`
const FindUserButton = styled.div`
    text-decoration: none;
    color: green;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid green;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    width: auto;
    margin-bottom: 20px;
`

const FoundMemberWrapper = styled.div`
    margin-bottom: 30px;
`

const Spacer = styled.div`
    margin-top: 15px;
`
const MuiPhoneInput = styled(Input)`
    width: 300px;
`

const AddUserModal = ({
    modalState, 
    handleModalClose, 
    handleFormSubmit, 
}) => {
    const [number, setNumber] = useState();
    const [userRole, setUserRole] = useState();
    const [error, setError] = useState();
    const [searchUserNumber, setSearchUserNumber] = useState()
    const [foundMember, setFoundMember] = useState(null)
    const [hasSearched, setHasSearched] = useState(false)
    const {
        phoneNumber,
        cleanPhoneNumber,
        onNumberChange,
        isValidPhoneNumber,
        removeCountryCodeAndChars
    } = usePhoneNumber('');

    const handleSearchUser = async() => {
        setHasSearched(true)
        const cleanPhone = removeCountryCodeAndChars(phoneNumber)
        console.log('typeof cleanPhone', typeof(cleanPhone))
        console.log('cleanPhone', cleanPhone);
        const memberResult = await API.graphql(graphqlOperation(userByPhone, {phone: cleanPhone}))
        console.log('memberResult', memberResult);
        if (memberResult.data.userByPhone.items && memberResult.data.userByPhone.items.length > 0) {
            setFoundMember(memberResult.data.userByPhone.items[0])
        }

    }

    const handleSaveUser = (type) => {
        setError(null);
        // if (!number || !isValidPhoneNumber(number)){
        //     setError('That is not a valid phone number');
        //     return;
        // }
        if (!userRole) {
            setError('You must select a role for the user');
            return;
        }
        // if (type === 'invite-member') {
        //     console.log('type invite-member')
        //     handleSendMemberInvite(number);
        //     return;
        // }
        // if (type === 'notify-user') {
        //     console.log('type send sms');
        //     handleSendUserNotification(number, userRole)
        //     return;
        // }
        console.log('type add user only');
        handleFormSubmit(foundMember, userRole)
    }

    const handleNumberChange = (event) => {
        const phone = event.target.value;
        onNumberChange(phone);
    }

    const handleRoleChange = (selectedOption) => {
        setError(null);
        setUserRole(selectedOption.value);
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-user-wrapper'>
                <FindUserWrapper>
                    Enter phone of person you want to add:
                    <Spacer></Spacer>
                    <input
                        type='text'
                        value={phoneNumber}
                        onChange={handleNumberChange}
                        placeholder="Enter Phone Number"
                    />
                    <FindUserButton onClick={() => handleSearchUser()}>Search user</FindUserButton>
                    {hasSearched && <FoundMemberWrapper>
                        {foundMember ? 
                        <>
                            Member Found: {foundMember.fName} {foundMember.lName} {foundMember.phone}
                        </>
                        :
                        <>
                            No member found for that number.  If that is the number for the user, have them first sign up.
                        </>}
                    </FoundMemberWrapper>
                    }
                </FindUserWrapper>
                {foundMember && 
                    <form>
                        <div className="role-descriptions">
                            Select a role for this user
                            <div className="role-description">
                                Super Admin: Can edit and view all things
                            </div>
                            <div className="role-description">
                                Admin: Can edit menu items and view all things
                            </div>
                            <div className="role-description">
                                Viewer: Can view all things
                            </div>
                        </div>
                        <div className="formInput" key='userRoleInput'>
                            <label>Role</label>
                            <Select 
                                options={userRoles} 
                                name="vendorState"
                                //value={stateData.find(opt => opt.value === formData.vendorState)}
                                onChange={handleRoleChange}
                                //defaultValue={}
                            />
                        </div>
                        {error && 
                            <Alert severity="error">
                                {error}
                            </Alert>
                        }
                        <div className='edit-modal-button-row'>
                            <button type="button" onClick={handleModalClose} >Cancel</button>
                            <button type='button' onClick={() => handleSaveUser()}>Add to your users</button>
                        </div>
                    </form>
                }
            </div>
        </Modal>
    )
}

export default AddUserModal;
