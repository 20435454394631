export const menuItemLinks = {
    newMenuItem: "/menuItems/new",
    newMenuItemText: "Add Menu Item",
    editMenuItemLink: "/menuItems/test",
    menuItemTitleText: "Current Menu Items"
};

export const menuItemColumns = [
    {
        field: "drink",
        headerName: "Drink",
        width: 230,
        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
                    {params.row.drink}
                </div>
            );
        },
    },
    {
        field: "description",
        headerName: "Description",
        width: 230,
    },

    {
        field: "price",
        headerName: "Price",
        width: 100,
    },
    {
        field: "mainCategoryName",
        headerName: "Category",
        width: 160,
        // renderCell: (params) => {
        //     return (
        //         <div className={`cellWithStatus ${params.row.status}`}>
        //             {params.row.status}
        //         </div>
        //     );
        // },
    },
    {
        field: "subCategoryName",
        headerName: "Sub Category",
        width: 160,
        // renderCell: (params) => {
        //     return (
        //         <div className={`cellWithStatus ${params.row.status}`}>
        //             {params.row.status}
        //         </div>
        //     );
        // },
    },
];