import * as React from 'react';

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Widget from "../../components/widget/Widget";
import TopNavBar from '../Landing/topNavBar';
import Footer from '../Landing/footer';
import VendorNameBlock from './VendorNameBlock';

////import Featured from "../../components/featured/Featured";
//import Chart from "../../components/chart/Chart";
//import Table from "../../components/table/Table";

const Home = () => {
  
  return (
    <>
      <TopNavBar />
      <div className="home">
        <Sidebar />
        <div className="homeContainer">
          <VendorNameBlock />
          <div className="widgets">
            <Widget type="user" />
            <Widget type="product" />
            <Widget type="order" />
            <Widget type="earning" />
          </div>
        <div className="charts">
            <Featured />
            <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
          </div>
          <div className="listContainer">
            <div className="listTitle">Latest Transactions</div>
            {/*<Table /> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;