import "./list.scss";
import * as React from 'react';
import SidebarDrinklinkAdmin from "../../components/sidebar/SidebarDrinklinkAdmin";
import DrinklinkAdminVendorsDatatable from "../../components/Vendors/drinklink-admin-vendors-datatable";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";

const DrinklinkAdminVendorsList = () => {

  return (
    <>
      <TopNavBar />
        <div className="list">
            <SidebarDrinklinkAdmin/>
            <div className="listContainer">
              <DrinklinkAdminVendorsDatatable />
            </div>
        </div>
      <Footer />
    </>
  )
}

export default DrinklinkAdminVendorsList