import * as React from 'react';
import Modal from '@mui/material/Modal';
import "./add-user-modal.scss";

const RemoveUserConfirmModal = ({
    modalState, 
    handleModalClose, 
    handleFormSubmit, 
    editingUser
}) => {
    console.log('currentUser', editingUser);

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-user-wrapper'>
                    <form>
                        <div className="role-descriptions">
                            Are you sure you want to remove user:
                            
                        </div>
                        {editingUser.fName && <div>
                        Name: {editingUser.fName} {editingUser.lName}
                        </div>}
                    
                        <div className='edit-modal-button-row'>
                            <button type="button" onClick={handleModalClose} >Cancel</button>
                            <button type='button' onClick={() => handleFormSubmit(editingUser)}>Yes - Remove User</button>       
                        </div>
                    </form>
            </div>
        </Modal>
    )
}

export default RemoveUserConfirmModal;
