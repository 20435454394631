import "./vendors-datatable.scss"
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useEffect, useState, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { RotatingLines } from 'react-loader-spinner'
import {listVendors} from '../../graphql/queries';
import { vendorColumns } from './drinklinkAdminVendorsDataTablesource';
import DrinklinkAdminEditVendorModal from "../../pages/DrinklinkAdminPages/drinklink-admin-edit-vendor-modal";
import { updateVendors } from "../../graphql/mutations";

const DrinklinkAdminVendorsDatatable = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [editVendorModalOpen, setEditVendorModalOpen] = useState(false)
    const [selectedVendorToEdit, setSelectedVendorToEdit] = useState()

    const handleEditVendorModalClose = () => {
        setEditVendorModalOpen(false)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const vendRet = await API.graphql(graphqlOperation(listVendors, { filter: {}}))
                const allVendors = vendRet.data.listVendors.items
                setData(allVendors)
                setIsLoading(false);
            } catch (e) {
                console.log('error fetching vendors', e)
                setIsLoading(false);
            }
        }
        fetchData()
    }, [])

    const handleEditVendor = (vendor) => {
        setSelectedVendorToEdit(vendor)
        setEditVendorModalOpen(true)
    }

    const handleEditVendorSubmit = async (values) => {
        const updateObject = {
            id: selectedVendorToEdit.id
        }
        const updatedUserVendorSelected = selectedVendorToEdit;
        if (values.vendorCity !== selectedVendorToEdit.vendorCity){
            updateObject.vendorCity = values.vendorCity.toLowerCase();
            updatedUserVendorSelected.vendorCity = values.vendorCity.toLowerCase();
        }
        if (values.vendorContactPhone !== selectedVendorToEdit.vendorContactPhone) {
            updateObject.vendorContactPhone = values.vendorContactPhone;
            updatedUserVendorSelected.vendorContactPhone = values.vendorContactPhone;
        }
        if (values.vendorEmail !== selectedVendorToEdit.vendorEmail) {
            updateObject.vendorEmail = values.vendorEmail;
            updatedUserVendorSelected.vendorEmail = values.vendorEmail;
        }
        if (values.vendorName !== selectedVendorToEdit.vendorName) {
            updateObject.vendorName = values.vendorName;
            updatedUserVendorSelected.vendorName = values.vendorName;
        }
        if (values.vendorState !== selectedVendorToEdit.vendorState) {
            updateObject.vendorState = values.vendorState.toLowerCase();
            updatedUserVendorSelected.vendorState = values.vendorState.toLowerCase();
        }
        if (values.vendorStreet !== selectedVendorToEdit.vendorStreet) {
            updateObject.vendorStreet = values.vendorStreet;
            updatedUserVendorSelected.vendorStreet = values.vendorStreet;
        }
        if (values.vendorZip !== selectedVendorToEdit.vendorZip) {
            updateObject.vendorZip = values.vendorZip;
            updatedUserVendorSelected.vendorZip = values.vendorZip;
        }
        if (values.status !== selectedVendorToEdit.status) {
            updateObject.status = values.status;
            updatedUserVendorSelected.status = values.status;
        }
        if (values.vendorDisplayStatus !== selectedVendorToEdit.vendorDisplayStatus) {
            updateObject.vendorDisplayStatus = values.vendorDisplayStatus;
            updatedUserVendorSelected.vendorDisplayStatus = values.vendorDisplayStatus;
        }
        if (values.waveDrinklinkFee !== selectedVendorToEdit.waveDrinklinkFee) {
            updateObject.waveDrinklinkFee = values.waveDrinklinkFee;
            updatedUserVendorSelected.waveDrinklinkFee = values.waveDrinklinkFee;
        }
        try {
            await API.graphql(graphqlOperation(updateVendors, {input: updateObject}))
        } catch (e) {
            console.log('error updating vendor', e)
        }
        setEditVendorModalOpen(false)
    }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div
                            className="viewButton"
                            onClick={() => handleEditVendor(params.row)}
                        >
                            Edit Vendor
                        </div>
                    </div>
                )
            }
        }
    ]
    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    All Vendors
                </div>
                {!isLoading ? 
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={vendorColumns.concat(actionColumn)}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            {editVendorModalOpen &&
                <DrinklinkAdminEditVendorModal 
                    modalState={editVendorModalOpen}
                    handleModalClose={handleEditVendorModalClose}
                    handleFormSubmit={handleEditVendorSubmit}
                    editingVendor={selectedVendorToEdit}
                />
            }
        </>
    )
}

export default DrinklinkAdminVendorsDatatable