export const listMainCategories = /* GraphQL */ `
  query ListMainCategories(
    $filter: ModelMainCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          items {
            id
            name
            mainCategoriesID
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
