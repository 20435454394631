import * as React from 'react';

export const userLinks = {
    newUser: "/users/new",
    newUserText: "Add New User",
    editUserLink: "/users/test",
    userTitleText: "Current Users"
};

export const userColumns = [
    {
        field: "fName",
        headerName: "First Name",
        width: 130,
        // renderCell: (params) => {
        //     return (
        //         <div className="cellWithImg">
        //             {/* <img className="cellImg" src={params.row.avatarImage} alt="avatar" /> */}
        //             {params.row.fName} {params.row.lName}
        //         </div>
        //     );
        // },
    },
    {
        field: "lName",
        headerName: "Last Name",
        width: 130,
        // renderCell: (params) => {
        //     return (
        //         <div className="cellWithImg">
        //             {/* <img className="cellImg" src={params.row.avatarImage} alt="avatar" /> */}
        //             {params.row.fName} {params.row.lName}
        //         </div>
        //     );
        // },
    },
    {
        field: "email",
        headerName: "Email",
        width: 230,
        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    {/* <img className="cellImg" src={params.row.avatarImage} alt="avatar" /> */}
                    {params.row.email}
                </div>
            );
        },
    },
    {
        field: "isVendorUser",
        headerName: "Vendor User",
        width: 160,
        rederCell: (params) => {
            return (
                <div>
                    {params.row.isVendorUser ? true : false}
                </div>
            )
        }
    },
    {
        field: "drinklinkUserRole",
        headerName: "Drinklink Admin",
        width: 160,
    }
    // {
    //     field: "role",
    //     headerName: "Role",
    //     width: 160
    // },
    // {
    //     field: "status",
    //     headerName: "Status",
    //     width: 160,
    //     renderCell: (params) => {
    //         return (
    //             <div className={`cellWithStatus ${params.row.status}`}>
    //                 {params.row.status}
    //             </div>
    //         );
    //     },
    // },
];