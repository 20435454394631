export const exampleMenuItemsData = [
    {
        description: 'Single',
        drink: 'Absolute',
        mainCategoryName: 'Liquor',
        subCategoryName: 'Vodka',
        price: 8.00
    },
    {
        description: 'Single',
        drink: 'Burnettes',
        mainCategoryName: 'Liquor',
        subCategoryName: 'Vodka',
        price: 6.00
    },
    {
        description: 'Double',
        drink: 'Burnettes',
        mainCategoryName: 'Liquor',
        subCategoryName: 'Vodka',
        price: 10.00
    },
    {
        description: 'single',
        drink: 'Burnettes',
        mainCategoryName: 'Liquor',
        subCategoryName: 'Vodka',
        price: 6.00
    },
    {
        description: 'single',
        drink: 'Jose Cuervo',
        mainCategoryName: 'Liquor',
        subCategoryName: 'Tequilla',
        price: 6.00
    },
    {
        description: 'draft',
        drink: 'Boneyard RPM',
        mainCategoryName: 'Beer',
        subCategoryName: 'Craft',
        price: 5.00
    },
    {
        description: 'draft',
        drink: 'Goodlife Descender',
        mainCategoryName: 'Beer',
        subCategoryName: 'Craft',
        price: 5.00
    },
    {
        description: 'draft',
        drink: 'Bud Light',
        mainCategoryName: 'Beer',
        subCategoryName: 'Domestic',
        price: 3.99
    },
    {
        description: 'Fountain',
        drink: 'Diet Coke',
        mainCategoryName: 'Non-alcoholic',
        subCategoryName: 'Soda',
        price: 1.99
    },
    {
        description: 'Percolated',
        drink: 'Coffee',
        mainCategoryName: 'Non-alcoholic',
        subCategoryName: '',
        price: 1.99
    },
    {
        description: 'T-shirt Swag all sizes',
        drink: 'T-shirt',
        mainCategoryName: 'Miscellaneous',
        subCategoryName: 'Branded tee',
        price: 16.99
    }
]