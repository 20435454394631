import * as React from 'react';
import { useState } from "react";
import { useCallback } from "react";
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';
import "./upload-menu-item-csv.scss";
import Select from 'react-select'
import "./menu-items-components.scss"

export const posPrividers = [
    {value: "square", label: "Square"},
    {value: "toast", label: "Toast"},
    {value: "drinklink", label: "Drinklink"}
]

const SelectWrapper = styled.div`
    margin-top: 30px;
    width: 300px;
`;

const InstructionsWrapper = styled.div`
    margin-top: 30px;
    width: 80%;
    font-size: 18px;
`


const UploadMenuItemCSVModal = ({
    handleParseCSVUploadData, 
    modalState, 
    handleModalClose,
    setPosSelected
}) => {
    const [isPosSelected, setIsPosSelected] = useState(false)
    const [theCsvFile, setTheCsvFile] = useState(null)
    const [theCsvFileName, setTheCsvFileName] = useState(null)
    const onDrop = useCallback(files => {
        if (files.length) {
            parseFile(files[0]);
        }
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'text/csv',
    });

    const onPosProviderChange = (theSelection) => {
        setPosSelected(theSelection.value);
        setIsPosSelected(true)
    }

    const onUploadCsvClick = () => {
        handleParseCSVUploadData(theCsvFile)
        handleModalClose()
    }

    const parseFile = file => {
        console.log('file', file)
        Papa.parse(file, {
            header: true,
            complete: results => {
                setTheCsvFile(results.data)
                setTheCsvFileName(file.name)
            }
        });
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='upload-menu-item-csv-wrapper'>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Upload your menu items from pos provider CSV file:
                </Typography>
                
                <InstructionsWrapper>
                    Select the POS provider that you exported your menu items csv file from.  If you downloaded the Drinklink template example and edited that, choose 'Drinklink'.  If you downloaded your menu from here and then edited it and are now uploading the csv with changes, select Drinklink.
                </InstructionsWrapper>
                <SelectWrapper>
                    <label>POS provider:</label>
                    <Select 
                        options={posPrividers} 
                        name="userRoleSelect"
                        defaultValue={posPrividers.find(opt => opt.value === posPrividers)}
                        onChange={onPosProviderChange}
                        //defaultValue={posPrividers.find(opt => opt.value === posPrividers)}
                    />
                </SelectWrapper>
                {isPosSelected && <div
                    {...getRootProps({
                    className: `dropzone 
                    ${isDragAccept && 'dropzoneAccept'} 
                    ${isDragReject && 'dropzoneReject'}`,
                    })}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p>Drag 'n' drop your csv file here, or click to select the file.</p>
                    )}
                </div>}
                {theCsvFile && theCsvFileName && 
                    <>
                        <div className='filename-div'>
                            Csv File to upload:
                        </div>
                        <div className='filename-div-name'>{theCsvFileName}</div>
                    </>
                }
                <div className='edit-modal-button-row'>
                    <button type="button" onClick={handleModalClose} >Cancel</button>
                    <button type='button' disable={(!isPosSelected).toString()} className={!isPosSelected ? "disabled" : null} onClick={() => onUploadCsvClick()}>Upload Menu</button>
                </div>
            </div>
        </Modal>
    )
}

export default UploadMenuItemCSVModal;