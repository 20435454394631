/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $input: CreateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    createUsers(input: $input, condition: $condition) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        items {
          id
          usersID
          friendUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $input: UpdateUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    updateUsers(input: $input, condition: $condition) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        items {
          id
          usersID
          friendUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $input: DeleteUsersInput!
    $condition: ModelUsersConditionInput
  ) {
    deleteUsers(input: $input, condition: $condition) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        items {
          id
          usersID
          friendUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMainCategories = /* GraphQL */ `
  mutation CreateMainCategories(
    $input: CreateMainCategoriesInput!
    $condition: ModelMainCategoriesConditionInput
  ) {
    createMainCategories(input: $input, condition: $condition) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      subCategories {
        items {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMainCategories = /* GraphQL */ `
  mutation UpdateMainCategories(
    $input: UpdateMainCategoriesInput!
    $condition: ModelMainCategoriesConditionInput
  ) {
    updateMainCategories(input: $input, condition: $condition) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      subCategories {
        items {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMainCategories = /* GraphQL */ `
  mutation DeleteMainCategories(
    $input: DeleteMainCategoriesInput!
    $condition: ModelMainCategoriesConditionInput
  ) {
    deleteMainCategories(input: $input, condition: $condition) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      subCategories {
        items {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubCategories = /* GraphQL */ `
  mutation CreateSubCategories(
    $input: CreateSubCategoriesInput!
    $condition: ModelSubCategoriesConditionInput
  ) {
    createSubCategories(input: $input, condition: $condition) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSubCategories = /* GraphQL */ `
  mutation UpdateSubCategories(
    $input: UpdateSubCategoriesInput!
    $condition: ModelSubCategoriesConditionInput
  ) {
    updateSubCategories(input: $input, condition: $condition) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubCategories = /* GraphQL */ `
  mutation DeleteSubCategories(
    $input: DeleteSubCategoriesInput!
    $condition: ModelSubCategoriesConditionInput
  ) {
    deleteSubCategories(input: $input, condition: $condition) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMenuItems = /* GraphQL */ `
  mutation CreateMenuItems(
    $input: CreateMenuItemsInput!
    $condition: ModelMenuItemsConditionInput
  ) {
    createMenuItems(input: $input, condition: $condition) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoriesID
      subCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateMenuItems = /* GraphQL */ `
  mutation UpdateMenuItems(
    $input: UpdateMenuItemsInput!
    $condition: ModelMenuItemsConditionInput
  ) {
    updateMenuItems(input: $input, condition: $condition) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoriesID
      subCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenuItems = /* GraphQL */ `
  mutation DeleteMenuItems(
    $input: DeleteMenuItemsInput!
    $condition: ModelMenuItemsConditionInput
  ) {
    deleteMenuItems(input: $input, condition: $condition) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoriesID
      subCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const createMenuItemsPurchased = /* GraphQL */ `
  mutation CreateMenuItemsPurchased(
    $input: CreateMenuItemsPurchasedInput!
    $condition: ModelMenuItemsPurchasedConditionInput
  ) {
    createMenuItemsPurchased(input: $input, condition: $condition) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      vendorNotes
      createdAt
      updatedAt
    }
  }
`;
export const updateMenuItemsPurchased = /* GraphQL */ `
  mutation UpdateMenuItemsPurchased(
    $input: UpdateMenuItemsPurchasedInput!
    $condition: ModelMenuItemsPurchasedConditionInput
  ) {
    updateMenuItemsPurchased(input: $input, condition: $condition) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      vendorNotes
      createdAt
      updatedAt
    }
  }
`;
export const deleteMenuItemsPurchased = /* GraphQL */ `
  mutation DeleteMenuItemsPurchased(
    $input: DeleteMenuItemsPurchasedInput!
    $condition: ModelMenuItemsPurchasedConditionInput
  ) {
    deleteMenuItemsPurchased(input: $input, condition: $condition) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      vendorNotes
      createdAt
      updatedAt
    }
  }
`;
export const createVendorUsers = /* GraphQL */ `
  mutation CreateVendorUsers(
    $input: CreateVendorUsersInput!
    $condition: ModelVendorUsersConditionInput
  ) {
    createVendorUsers(input: $input, condition: $condition) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVendorUsers = /* GraphQL */ `
  mutation UpdateVendorUsers(
    $input: UpdateVendorUsersInput!
    $condition: ModelVendorUsersConditionInput
  ) {
    updateVendorUsers(input: $input, condition: $condition) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendorUsers = /* GraphQL */ `
  mutation DeleteVendorUsers(
    $input: DeleteVendorUsersInput!
    $condition: ModelVendorUsersConditionInput
  ) {
    deleteVendorUsers(input: $input, condition: $condition) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDrinklinkAdmins = /* GraphQL */ `
  mutation CreateDrinklinkAdmins(
    $input: CreateDrinklinkAdminsInput!
    $condition: ModelDrinklinkAdminsConditionInput
  ) {
    createDrinklinkAdmins(input: $input, condition: $condition) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDrinklinkAdmins = /* GraphQL */ `
  mutation UpdateDrinklinkAdmins(
    $input: UpdateDrinklinkAdminsInput!
    $condition: ModelDrinklinkAdminsConditionInput
  ) {
    updateDrinklinkAdmins(input: $input, condition: $condition) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDrinklinkAdmins = /* GraphQL */ `
  mutation DeleteDrinklinkAdmins(
    $input: DeleteDrinklinkAdminsInput!
    $condition: ModelDrinklinkAdminsConditionInput
  ) {
    deleteDrinklinkAdmins(input: $input, condition: $condition) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBartenders = /* GraphQL */ `
  mutation CreateBartenders(
    $input: CreateBartendersInput!
    $condition: ModelBartendersConditionInput
  ) {
    createBartenders(input: $input, condition: $condition) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
    }
  }
`;
export const updateBartenders = /* GraphQL */ `
  mutation UpdateBartenders(
    $input: UpdateBartendersInput!
    $condition: ModelBartendersConditionInput
  ) {
    updateBartenders(input: $input, condition: $condition) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
    }
  }
`;
export const deleteBartenders = /* GraphQL */ `
  mutation DeleteBartenders(
    $input: DeleteBartendersInput!
    $condition: ModelBartendersConditionInput
  ) {
    deleteBartenders(input: $input, condition: $condition) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
    }
  }
`;
export const createVendors = /* GraphQL */ `
  mutation CreateVendors(
    $input: CreateVendorsInput!
    $condition: ModelVendorsConditionInput
  ) {
    createVendors(input: $input, condition: $condition) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      waveDrinklinkFee
      createdAt
      updatedAt
    }
  }
`;
export const updateVendors = /* GraphQL */ `
  mutation UpdateVendors(
    $input: UpdateVendorsInput!
    $condition: ModelVendorsConditionInput
  ) {
    updateVendors(input: $input, condition: $condition) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      waveDrinklinkFee
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendors = /* GraphQL */ `
  mutation DeleteVendors(
    $input: DeleteVendorsInput!
    $condition: ModelVendorsConditionInput
  ) {
    deleteVendors(input: $input, condition: $condition) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      waveDrinklinkFee
      createdAt
      updatedAt
    }
  }
`;
export const createFriends = /* GraphQL */ `
  mutation CreateFriends(
    $input: CreateFriendsInput!
    $condition: ModelFriendsConditionInput
  ) {
    createFriends(input: $input, condition: $condition) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateFriends = /* GraphQL */ `
  mutation UpdateFriends(
    $input: UpdateFriendsInput!
    $condition: ModelFriendsConditionInput
  ) {
    updateFriends(input: $input, condition: $condition) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteFriends = /* GraphQL */ `
  mutation DeleteFriends(
    $input: DeleteFriendsInput!
    $condition: ModelFriendsConditionInput
  ) {
    deleteFriends(input: $input, condition: $condition) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
