import "./drinks-datatable.scss";
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";
import moment from 'moment';
import {
    listMenuItemsPurchaseds
} from "../../../src/graphql/queries"
import {
    updateMenuItemsPurchased
} from "../../../src/graphql/mutations"
import styled from 'styled-components';

import { last } from 'underscore';
import { RotatingLines } from 'react-loader-spinner';

import { drinkColumns } from "./drinksdatatablesource";
import DrinkDetailsModal from "./drinks-details-modal";
import DrinkNotesModal from "./drinks-notes-modal";
import {VendorUserContext} from "../../context/VendorUserContext";

const TotalsDisplayWrap = styled.div`
    margin: 100px 30px;
`

const DrinksDataTable = () => {
    const [drinksData, setDrinksData] = useState([]);
    const [drinksDataFormatted, setDrinksDataFormatted] = useState([]);
    const [drinkDetailsModalOpen, setDrinkDetailsModalOpen] = useState(false);
    const [detailsModalDrink, setDetailsModalDrink] = useState(null);
    const [drinkNotesModalOpen, setDrinkNotesModalOpen] = useState(false);
    const [notesModalDrink, setNotesModalDrink] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [totalsObject, setTotalsObject] = useState([])

    const { userVendorSelected } = useContext(VendorUserContext);
    console.log('userVendorSelected', userVendorSelected)

    const formatData = (data) => {
        const theData = [];
        data.forEach(drink => {
            const theDrink = { ...drink };
            const lastItem = last(JSON.parse(theDrink.stateHistory));
            theDrink.status = lastItem.drink_status;
            theDrink.statusDate = moment(lastItem.date).format('MMM Do YYYY HH:MM:SS');
            theData.push(theDrink);
        });
        return theData;
    }

    const handleDetails = (drink) => {
        const drinkData = drinksData.find(x => x.id === drink)
        setDetailsModalDrink(drinkData);
        setDrinkDetailsModalOpen(true);
    }

    const handleDetailsClose = () => {
        setDrinkDetailsModalOpen(false);
    }

    const handleNotesClose = () => {
        setDrinkNotesModalOpen(false);
    }

    const handleAddNotes = (drink) => {
        setNotesModalDrink(drink);
        setDrinkNotesModalOpen(true);
    }

    const updateNotesItem = async (notes, drinkId) => {
        console.log('notes', notes);
        console.log('drinkId', drinkId)
        
        const updateObject = {
            id: drinkId,
            vendorNotes: JSON.stringify(notes)
        }
        try {
            await API.graphql(graphqlOperation(
                updateMenuItemsPurchased, {
                    input: updateObject
                }
            ))
            return true;
        } catch (e) {
            console.log('error updating notes', e)
        }
    }

    const handleNoteSubmit = async (notes, drinkId) => {
        const res = updateNotesItem(notes, drinkId);
        if (!res) {
            return;
        }
        const newData = [...drinksData];
        const foundIndex = drinksData.findIndex(dk => dk.id === drinkId);
        newData[foundIndex].notes = notes;
        setDrinksData(newData);
        setDrinksDataFormatted(formatData(newData));
        setDrinkNotesModalOpen(false);
    }

    const getTotals = (menuItems) => {
        let totalPrice = 0;
        let totalTip = 0;
        let total = 0
        for(const item of menuItems) {
            totalPrice = parseFloat(totalPrice).toFixed(2) + parseFloat(item.drinkCost).toFixed(2)
        }
        console.log('totalPrice', totalPrice)
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const menuItemsPurchasedReturn = await API.graphql(graphqlOperation(
                    listMenuItemsPurchaseds, {
                        filter: {
                            vendorsID: {eq: userVendorSelected.id}
                        }
                    }
                ))
                let menuItemsPurchased = menuItemsPurchasedReturn.data.listMenuItemsPurchaseds.items
                getTotals(menuItemsPurchased)
                setDrinksData(menuItemsPurchased);
                setDrinksDataFormatted(formatData(menuItemsPurchased));
                setIsLoading(false);
            } catch (err) {
                console.log('error: ', err);
                setIsLoading(false);
            }
        };
        fetchData();

    }, [userVendorSelected]);

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div 
                            className="viewButton"
                            onClick={() => handleAddNotes(params.row)}
                        >   
                            Add Notes
                        </div>
                        <div
                            className="deleteButton"
                            onClick={() => null}
                        >
                            Cancel
                        </div>
                        <div
                            className="detailsButton"
                            onClick={() => handleDetails(params.row.id)}
                        >
                            Details
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    Drink Purchase History
                    <div className="dataTableTitleButtons">
                        <>
                            <div onClick={() => null} className="link">
                                Download Purchases CSV
                            </div>
                            {/* <div onClick={() => null} className="link">
                                Upload Purchase CSV
                            </div> */}
                        </>
                        <div onClick={() => null} className="link">
                            Buy drink
                        </div>
                    </div>
                </div>
                {!isLoading ?
                    <DataGrid
                        className="datagrid"
                        rows={drinksDataFormatted}
                        columns={drinkColumns.concat(actionColumn)}
                        pageSize={25}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>

            <TotalsDisplayWrap>
                totals go here: 
            </TotalsDisplayWrap>
            { drinkDetailsModalOpen && <DrinkDetailsModal modalState={drinkDetailsModalOpen} theDrink={detailsModalDrink} handleModalClose={handleDetailsClose} /> }
            { drinkNotesModalOpen && <DrinkNotesModal modalState={drinkNotesModalOpen} theDrink={notesModalDrink} handleModalClose={handleNotesClose} handleNoteSubmit={handleNoteSubmit} /> }
        </>
    );
}

export default DrinksDataTable;