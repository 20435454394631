import "./vendorInfo.scss"
import * as React from 'react';
import { useState, useEffect, useContext } from "react";
import styled from 'styled-components';
import {Amplify, API, graphqlOperation, Storage} from 'aws-amplify';
import { updateVendors } from "../../../src/graphql/mutations";

//import EmailSender from '../../utils/email-sender';
import Sidebar from "../../components/sidebar/Sidebar";
import EditVendorModal from "../../components/Vendors/edit-vendor-modal";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";

//import { testingSendingEmail } from '../../utils/email-sender';
import { VendorUserContext } from "../../context/VendorUserContext";

const VendorInfoWrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
`;

const VendorInfoImage = styled.div`
-webkit-box-shadow: 5px 10px 30px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 5px 10px 20px 3px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
`;

const VendorInfoDetails = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const VendorInfoImageImg = styled.img`
    width: 300px;
    height: 200px;
    object-fit: cover;
    -webkit-box-shadow: 5px 10px 30px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 5px 10px 20px 3px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
`;

const VendorInfoEditInfo = styled.div`
    background-color: #844454;
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    width: 50%;
    text-align: center;
    margin: 15px 20px;
`

const VendorInfoName = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
`;

const VendorInfoContact = styled.div`
`;

const VendorInfoRowText = styled.div`
    font-size: 18px;
    margin-bottom
`;

const VendorInfoLocation = styled.div`

`;

const VendorInfoContactTitle = styled.div`
    font-size: 20px;
`;

const VendorInfoLocationTitle = styled.div`
    margin-top: 10px;
    font-size: 20px;
`;

const VendorInfoUpdateErrorMessage = styled.div`
    color: red;
    font-size: 18px;
`

const VendorInfo = () => {
  const [vendorData, setVendorData] = useState(null);
  const [editVendorModalOpen, setEditVendorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { userVendorSelected, dispatch } = useContext(VendorUserContext);

  useEffect(() => {
    const fetchData = async () => {
        try {
            setVendorData(userVendorSelected);
        } catch (err) {
            console.log('error: ', err);
        }
    };
    fetchData();
  }, [userVendorSelected]);

  const handleEditVendorModalClose = () => {
    setEditVendorModalOpen(false);
  }

  const updateVendor = async(incommingData, imageData) => {
    const updateObject = {
        id: userVendorSelected.id
    }
    const updatedUserVendorSelected = userVendorSelected;
    if (incommingData.vendorCity !== vendorData.vendorCity){
        updateObject.vendorCity = incommingData.vendorCity.toLowerCase();
        updatedUserVendorSelected.vendorCity = incommingData.vendorCity.toLowerCase();
    }
    if (incommingData.vendorContactPhone !== vendorData.vendorContactPhone) {
        updateObject.vendorContactPhone = incommingData.vendorContactPhone;
        updatedUserVendorSelected.vendorContactPhone = incommingData.vendorContactPhone;
    }
    if (incommingData.vendorEmail !== vendorData.vendorEmail) {
        updateObject.vendorEmail = incommingData.vendorEmail;
        updatedUserVendorSelected.vendorEmail = incommingData.vendorEmail;
    }
    if (incommingData.vendorName !== vendorData.vendorName) {
        updateObject.vendorName = incommingData.vendorName;
        updatedUserVendorSelected.vendorName = incommingData.vendorName;
    }
    if (incommingData.vendorState !== vendorData.vendorState) {
        updateObject.vendorState = incommingData.vendorState.toLowerCase();
        updatedUserVendorSelected.vendorState = incommingData.vendorState.toLowerCase();
    }
    if (incommingData.vendorStreet !== vendorData.vendorStreet) {
        updateObject.vendorStreet = incommingData.vendorStreet;
        updatedUserVendorSelected.vendorStreet = incommingData.vendorStreet;
    }
    if (incommingData.vendorZip !== vendorData.vendorZip) {
        updateObject.vendorZip = incommingData.vendorZip;
        updatedUserVendorSelected.vendorZip = incommingData.vendorZip;
    }

    try {
        if (imageData) {
            const imgStorageName = `${userVendorSelected.id}-${new Date().getTime()}.jpg`;
            const result = await Storage.put(imgStorageName, imageData, {
                contentType: imageData.type
            });
            console.log('result', result);
            const imageUri = await Storage.get(result.key);
            console.log('imageUri', imageUri);
            updateObject.vendorImage = result.key;
            updatedUserVendorSelected.vendorImage = result.key;
            updatedUserVendorSelected.vendorImageUrl = imageUri;
        }
        console.log('updateObject', updateObject);
        const updatedVendor = await API.graphql(graphqlOperation(updateVendors, {input: updateObject}))

        console.log('updatedVendor', updatedVendor);

        dispatch({type: "SET_USER_VENDOR_SELECTED", payload: updatedUserVendorSelected})
    } catch (e) {
        console.log('error updating vendor', e);
        setErrorMessage(e);
    }
    setEditVendorModalOpen(false);
  }

  const handleEditVendorFormSubmit = (incommingData, imageData) => {
    console.log('imageData here....', imageData);
    if (incommingData.vendorName === '' || incommingData.vendorStreet === '' || incommingData.vendorCity === '' || incommingData.vendorState === '' || incommingData.vendorZip === '' || incommingData.vendorContactPhone === '') {
        return;
    }
    updateVendor(incommingData, imageData); 
  }

  const myFormatPhoneNumber = (numStr) => {
    if (numStr.length < 2) {
        return numStr;
    }

    var processed = '';
    if (numStr[0] === '1') {
        processed = '1 ';
        numStr = numStr.substr(1);
    }

    processed += '(' + numStr.substr(0, 3);
    if (numStr.length > 3) {
        processed += ') ' + numStr.substr(3, 3);
        if (numStr.length > 6) {
            processed += '-' + numStr.substr(6, 4);
        }
    }
    return processed;
}

const capitalizeFirstLetter = (word) => {
    const capitalized =
    word.charAt(0).toUpperCase()
    + word.slice(1)
    return capitalized;
}

  console.log('vendorData', vendorData)
  // const testingSendingEmail = () => {
  //   console.log('in testingSending Email');
  //   const email = new EmailSender();
  //   try {
  //     //email.sendAddMemberRequest();
  //     console.log('sending email: ', JSON.stringify(email));
  //   } catch (e) {
  //     console.log('error sending email', e);
  //   }
    
  // }

  return (
    <>
        <TopNavBar />
        <div className="list">
            <Sidebar/>
            <div className="listContainer">

                {vendorData && <>
                    <VendorInfoWrapper>
                    {/*<div onClick={() => testingSendingEmail()}>send email</div>*/}
                        {vendorData?.vendorImage && <VendorInfoImage>
                            <VendorInfoImageImg src={vendorData.vendorImageUrl} alt=''/>
                        </VendorInfoImage>}

                    <VendorInfoDetails>
                        <VendorInfoName>{vendorData.vendorName}</VendorInfoName>
                        <VendorInfoContact>
                            <VendorInfoContactTitle>Contact Info:</VendorInfoContactTitle>
                            <VendorInfoRowText>Email: {vendorData.vendorEmail}</VendorInfoRowText>
                            <VendorInfoRowText>Phone: {myFormatPhoneNumber(vendorData.vendorContactPhone)}</VendorInfoRowText>
                        </VendorInfoContact>
                        <VendorInfoLocation>
                            <VendorInfoLocationTitle>Location:</VendorInfoLocationTitle>
                            <VendorInfoRowText>{vendorData.vendorStreet}</VendorInfoRowText>
                            <VendorInfoRowText>{capitalizeFirstLetter(vendorData.vendorCity)}, {vendorData.vendorState.toUpperCase()}</VendorInfoRowText>
                        </VendorInfoLocation>
                    </VendorInfoDetails>
                    </VendorInfoWrapper>
                    {errorMessage && <VendorInfoUpdateErrorMessage>{errorMessage}</VendorInfoUpdateErrorMessage>}
                    <VendorInfoEditInfo onClick={() => setEditVendorModalOpen(true)}>Edit Vendor Info</VendorInfoEditInfo>
                </>}
            </div>
        {editVendorModalOpen && vendorData &&
                <EditVendorModal
                    modalState={editVendorModalOpen}
                    handleModalClose={handleEditVendorModalClose}
                    handleFormSubmit={handleEditVendorFormSubmit}
                    editingVendor={vendorData}
                >
                </EditVendorModal>
            }
        </div>
        <Footer />
    </>
  )
}

export default VendorInfo