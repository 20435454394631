const VendorUserReducer = (state, action) => {
    switch (action.type) {
        case "SET_USER_VENDORS": {
            return {
                userVendors: action.payload,
            }
        }
        case "UNSET_USER_VENDORS": {
            return {
                userVendors: []
            }
        }
        case "SET_USER_VENDOR_SELECTED": {
            return {
                userVendorSelected: action.payload,
            }
        }
        case "UNSET_USER_VENDOR_SELECTED": {
            return {
                userVendorSelected: null
            }
        }
        default:
            return state;
    }
};

export default VendorUserReducer;