export const formatMyDate = (timestamp) => {
    const options = {year: "numberic", month: "long", day: "numeric"}
    return new Date(timestamp).toLocaleDateString(undefined, options);
}

export const formatMoney = (amount) => {
    return (Math.round(amount * 100) / 100).toFixed(2);
}

export const isValidEmail = (email) => {
    const res = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    console.log('res', res);
    return res;
}

export const userRoles = [
    {value: "superAdmin", label: "Super Admin"},
    {value: "admin", label: "Admin"},
    {value: "viewer", label: "Viewer"}
]