import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import {API, graphqlOperation} from "aws-amplify";
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import "./edit-menu-item-modal.scss";
import "./edit-categories-modal.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
//import Paper from '@mui/material/Paper';
import { VendorUserContext } from "../../context/VendorUserContext";
import { 
    createSubCategories,
    deleteSubCategories,
    deleteMainCategories,
    createMainCategories
} from "../../../src/graphql/mutations";

const InstructionsText = styled.div`
    font-size: 16px;
    text-align: left;
    margin-bottom: 10px;
`;

const CategoryWrap = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
`

const SubListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const TrashCanWrapper = styled.div`
    padding-left: 10px;
    cursor: pointer;
`

const DeleteButton = styled.button`
    width: 25%;
    font-size: 16px;
    border-radius: 8px
`
const SubDeleteButton = styled.button`
    width: 25%
    font-size: 16px;
    border-radius: 8px;
`
const SaveSubCatButton = styled.button`
    width: 60%;
    border-radius: 8px;
    max-width: 150px;
`

const NewCategoryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 30px;
    padding: 10px 20px;
    border: 2px solid grey;
    border-radius: 5px;
    margin-top: 30px;
`
const NewSubCategoryWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 30px;
`

const EditCategoriesModal = ({
    modalState,
    handleModalClose,
    categories,
    setCategories
}) => {
    //const [categories, setCategories] = useState(categoriesIn)
    const [values, setValues] = useState([])
    const { userVendorSelected } = useContext(VendorUserContext);
    const [hasDeletedItem, setHasDeletedItem] = useState(false);
    const [newCategory, setNewCategory] = useState('')

    const handleTrashClick = async (mainId, subId) => {
        try {
            const deleteResult = await API.graphql(graphqlOperation(deleteSubCategories, {input: {id: subId}}))
            if (deleteResult) {
                const theseCategories = [...categories];
                const foundMain = theseCategories.findIndex((cat) => cat.id === mainId);
                const theseSubs = theseCategories[foundMain].subCategories.items;
                const filterResult = theseSubs.filter((sub) => subId !== sub.id)
                theseCategories[foundMain].subCategories.items = filterResult
                setCategories(theseCategories)
            }
        } catch (e) {
            console.log('error deleting sub category', e)
        }
        setHasDeletedItem(true);
    }

    const handleTrashClickMain = async (catId) => {
        try {
            const theseCategories = [...categories];
            // delete all subs for this category in db. We don't want orphaned subs.
            const foundMain = theseCategories.findIndex((cat) => cat.id === catId);
            const theseSubs = theseCategories[foundMain].subCategories.items;
            if (theseSubs.length > 0) {
                const subsMutation = theseSubs.map((itm, i) => {
                    return `mutation${i}: deleteSubCategories(input: {id: "${itm.id}"}) { id }`;
                });
                await API.graphql(
                    graphqlOperation(`
                    mutation batchMutation {
                        ${subsMutation}
                        }
                    `)
                );
            }
            
            // now delete main category.
            await API.graphql(graphqlOperation(deleteMainCategories, {input: {id: catId}}))

            // now update categories state removing this one and its subs
            const filterResult = theseCategories.filter((cat) => catId !== cat.id)
            setCategories(filterResult)
            setHasDeletedItem(true);
        } catch (e) {
            console.log('error deleting main category', e)
        }
    }

    const handleSaveNewCategory = async () => {
        console.log('newCategory', newCategory);
        const newInput = {
            name: newCategory,
            vendorsID: userVendorSelected.id,
        }
        const addedCategory = await API.graphql(graphqlOperation(createMainCategories, {input: newInput}))
        const newMainCategory = addedCategory.data.createMainCategories
        const theseCategories = [...categories];
        theseCategories.push(newMainCategory)
        setCategories(theseCategories)
        setNewCategory('')
        console.log('newMainCategory', newMainCategory);
    }

    const handleSubInputChange = (catId, e) => {
        const theseValues = [...values];
        const foundSubIndex = theseValues.findIndex((sub) => catId === sub.catId);
        if (foundSubIndex > -1) {
            theseValues[foundSubIndex] = {catId: catId, name: e.target.value}
        } else {
            theseValues.push({catId: catId, name: e.target.value})
        }
        setValues(theseValues)
    }

    const saveNewSubCatToDB = async (mainCatId, subCatName) => {
        console.log('mainCatId', mainCatId);
        console.log('new sub name: ', subCatName)
        const newInput = {
            name: subCatName,
            vendorsID: userVendorSelected.id,
            mainCategoriesID: mainCatId
        }
        const addedMenuItem = await API.graphql(graphqlOperation(createSubCategories, {input: newInput}))
        return addedMenuItem.data.id
    }

    const handleSaveNewSub = (catId) => {
        const theseValues = [...values]
        // find the right one to save
        const foundSubIndex = theseValues.findIndex((sub) => catId === sub.catId);
        const newSubName = theseValues[foundSubIndex].name
        // save it
        const newSubCatId = saveNewSubCatToDB(catId, newSubName)
        // remove from values
        const filterResult = theseValues.filter((sub) => catId !== sub.catId)
        // save to state
        setValues(filterResult)
        // add to cats array
        const theseCategories = [...categories]
        const foundCategoryIndex = theseCategories.findIndex((cat) => cat.id === catId);
        if (foundCategoryIndex > -1) {
            const theSubs = theseCategories[foundCategoryIndex].subCategories.items
            theSubs.push({ subcategoryId: newSubCatId, name: newSubName})
        }
        setCategories(theseCategories)
    }

    const onModalClose = () => {
        handleModalClose(hasDeletedItem)
    }

    console.log('categoriesIn', categories)
    return (
        <Modal
            open={modalState}
            onClose={onModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-menu-item-wrapper'>
                <InstructionsText>
                    * Deleting a subcategory will remove it from any menu items using it. Re-creating it will not add it back to the menu item.  You will have to edit the menu item itself to add a category or sub category back.
                </InstructionsText>
                <InstructionsText>
                    * Deleting a category will also delete all of its sub categories.
                </InstructionsText>
                <NewCategoryWrapper>
                    <input
                        type='text'
                        placeholder="Enter new Category"
                         //value={values[cat.id]}
                        name={'new-category'}
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        //onChange={handleNewSubChange(this.value)}
                     >
                    </input> 
                    <SaveSubCatButton
                        onClick={() => handleSaveNewCategory()}
                    >
                        Add New Main Category
                    </SaveSubCatButton>
                </NewCategoryWrapper>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Category</TableCell>
                                <TableCell>Sub Categories</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((cat) => (
                                <TableRow
                                    key={cat.name}
                                    sx={{ '&:last-child td, &:loast-child th': { border: 0}}}
                                >
                                    <TableCell component="th" scope="row">
                                        <CategoryWrap>
                                            <SubListWrapper>{cat.name} 
                                                <TrashCanWrapper
                                                    onClick={() => handleTrashClickMain(cat.id)}
                                                >
                                                    <DeleteForeverIcon />
                                                </TrashCanWrapper>
                                            </SubListWrapper>
                                        </CategoryWrap>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <ul>
                                        {cat.subCategories && cat.subCategories.items.map((sub) => {
                                            return <li>
                                                <SubListWrapper>{sub.name} 
                                                    <TrashCanWrapper
                                                        onClick={() => handleTrashClick(cat.id, sub.id)}
                                                    >
                                                        <DeleteForeverIcon />
                                                    </TrashCanWrapper>
                                                </SubListWrapper>
                                            </li>
                                        })}
                                        </ul>
                                        <NewSubCategoryWrapper>
                                            <input
                                                type='text'
                                                placeholder="Enter new subcategory"
                                                //value={values[cat.id]}
                                                name={`${cat.id}-new`}
                                                //value={newSubCat.label}
                                                onChange={(e) => handleSubInputChange(cat.id, e)}
                                                //onChange={handleNewSubChange(this.value)}
                                            >
                                            </input> 
                                            <SaveSubCatButton
                                                onClick={() => handleSaveNewSub(cat.id)}
                                            >
                                                Add New Sub Category
                                            </SaveSubCatButton>
                                        </NewSubCategoryWrapper>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Modal>
    )
}

export default EditCategoriesModal;