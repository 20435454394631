import * as React from 'react';
import styled from 'styled-components';

const CategoryItemWrap = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid grey;
    border-radius: 10px;
`
const CategoryTitle = styled.div`
`
const CategoryButtonsRow = styled.div`
    display: flex;
    gap: 15px;
    flex-direction: row;
    justify-content: space-between;
`
const CategoryButtonDelete = styled.div`
    padding: 2px 5px;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    cursor: pointer;
`
const CategoryButtonEdit = styled.div`
    padding: 2px 5px;
    border: 1px solid blue;
    border-radius: 5px;
    color: blue;
    cursor: pointer;
`

const SubCategoryBox = ({
    subCategory,
    handleDeleteSub
}) => {
  return (
    <CategoryItemWrap>
      <CategoryTitle>{subCategory.name}</CategoryTitle>
      <CategoryButtonsRow>
        <CategoryButtonDelete
            onClick={() => handleDeleteSub(subCategory.id)}
        >
            Delete
        </CategoryButtonDelete>
        <CategoryButtonEdit
            //onClick={() => setSubCatSelected(subCategory)}
        >
            Edit Modifiers
        </CategoryButtonEdit>
      </CategoryButtonsRow>
    </CategoryItemWrap>
  )
}

export default SubCategoryBox