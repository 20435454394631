import * as React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import Modal from '@mui/material/Modal';
import "./editDetailsModal.scss";
import styled from 'styled-components';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { stateData } from '../../utils/state-data';

const SaveItemButton = styled.button`
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 6px;
`;

const SaveItemsButtonsRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const BirthDayNote = styled.div`
    margin-top: -15px;
    color: #844454
`

const EditDetailsModal = ({modalState, handleModalClose, handleFormSubmit, currentUser}) => {
    const [formData, setFormData] = useState({
        fName: currentUser?.fName ? currentUser.fName : '',
        lName: currentUser?.lName ? currentUser.lName : '',
        username: currentUser?.username ? currentUser.username : '',
        email: currentUser?.email ? currentUser.email : '',
        homeCity: currentUser?.homeCity ? currentUser.homeCity : '',
    });
    const [birthDate, setBirthDate] = useState(currentUser.birthDate);
    const [homeState, setHomeState] = useState(currentUser.homeState ? currentUser.homeState : null);
    
    const handleInputChange = (event) => {
        //setErrorMessage([]);
        const value = event.target.value;
         
        setFormData({
            ...formData,
            [event.target.name] : value
        })
    }

    const handleSaveChanges = () => {
        const newFormData = { ...formData, birthDate: birthDate, homeState: homeState}
        handleFormSubmit(newFormData);
    }

    const handleSetBirthDate = (date) => {
        const newDate = new Date(`${date.$y}-${date.$M + 1}-${date.$D}`);
        const dateString = newDate.getTime();
        setBirthDate(dateString);
    }

    const handleSetHomeState = (selectedOption) => {
        setHomeState(selectedOption.value);
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-menu-item-wrapper'>
                <form>
                    <div className="formInput" key='fName'>
                        <label>First Name</label>
                        <input 
                            type='text' 
                            placeholder="John"
                            name='fName'
                            value={formData.fName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key='lName'>
                        <label>Last Name</label>
                        <input 
                            type='text' 
                            placeholder="Smith"
                            name='lName'
                            value={formData.lName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key='username'>
                        <label>Username</label>
                        <input 
                            type='text' 
                            placeholder="jsmith"
                            name='username'
                            value={formData.username}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key='email'>
                        <label>Email</label>
                        <input 
                            type='text' 
                            placeholder="some@some.com"
                            name='email'
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key='homeCity'>
                        <label>Home City</label>
                        <input 
                            type='text' 
                            placeholder="Portland"
                            name='homeCity'
                            value={formData.homeCity}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key='homeState'>
                        <label>Home State</label>
                        <Select 
                            options={stateData} 
                            name="homeState"
                            onChange={handleSetHomeState}
                            defaultValue={stateData.find(opt => opt.value === currentUser.homeState)}
                        />
                    </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                {/*<DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} /> */}
                                <DatePicker
                                    className="datpicker-button"
                                    label="Birthday"
                                    value={dayjs(birthDate)}
                                    onChange={(newValue) => handleSetBirthDate(newValue)}
                                    closeOnSelect={false}
                                    showDaysOutsideCurrentMonth
                                />
                            </DemoContainer>
                            <BirthDayNote>* Birthday year is never shown except to you.</BirthDayNote>
                        </LocalizationProvider>
                    <SaveItemsButtonsRow>
                        <SaveItemButton type="button" onClick={handleModalClose} >Cancel</SaveItemButton>
                        <SaveItemButton type='button' onClick={() => handleSaveChanges()}>Save Changes</SaveItemButton>
                    </SaveItemsButtonsRow>
                </form>
            </div>
        </Modal>
    )
}

export default EditDetailsModal;