/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!) {
    getUsers(id: $id) {
      id
      avatarImage
      birthDate
      email
      fName
      homeCity
      homeState
      isVendorUser
      lName
      lowerCaseUsername
      phone
      smsNotificationsAccept
      stripeCustomerId
      username
      emailNotificationsAccept
      userAskedToDelete
      deletedAt
      Friends {
        items {
          id
          usersID
          friendUserID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMainCategories = /* GraphQL */ `
  query GetMainCategories($id: ID!) {
    getMainCategories(id: $id) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      subCategories {
        items {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMainCategories = /* GraphQL */ `
  query ListMainCategories(
    $filter: ModelMainCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubCategories = /* GraphQL */ `
  query GetSubCategories($id: ID!) {
    getSubCategories(id: $id) {
      id
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSubCategories = /* GraphQL */ `
  query ListSubCategories(
    $filter: ModelSubCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuItems = /* GraphQL */ `
  query GetMenuItems($id: ID!) {
    getMenuItems(id: $id) {
      id
      description
      drink
      price
      vendorsID
      mainCategoriesID
      mainCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoriesID
      subCategory {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      createdAt
      updatedAt
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        subCategoriesID
        subCategory {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMenuItemsPurchased = /* GraphQL */ `
  query GetMenuItemsPurchased($id: ID!) {
    getMenuItemsPurchased(id: $id) {
      id
      category
      personalMessage
      state
      stateHistory
      stripeStatus
      stripeStatusHistory
      stripeReceiptUrl
      drinkCost
      fees
      menuItemDescription
      menuItemName
      stripePaymentIntentID
      tip
      purchaserID
      purchaser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      receiverID
      receiver {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      vendorsID
      vendor {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      vendorNotes
      createdAt
      updatedAt
    }
  }
`;
export const listMenuItemsPurchaseds = /* GraphQL */ `
  query ListMenuItemsPurchaseds(
    $filter: ModelMenuItemsPurchasedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMenuItemsPurchaseds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        purchaser {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        receiverID
        receiver {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        vendorsID
        vendor {
          id
          status
          vendorDisplayStatus
          vendorCity
          vendorContactPhone
          vendorEmail
          vendorImage
          vendorName
          vendorState
          vendorStreet
          vendorZip
          waveDrinklinkFee
          createdAt
          updatedAt
        }
        vendorNotes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendorUsers = /* GraphQL */ `
  query GetVendorUsers($id: ID!) {
    getVendorUsers(id: $id) {
      id
      lastAccess
      role
      status
      vendorsID
      vendors {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVendorUsers = /* GraphQL */ `
  query ListVendorUsers(
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendorUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        vendors {
          id
          status
          vendorDisplayStatus
          vendorCity
          vendorContactPhone
          vendorEmail
          vendorImage
          vendorName
          vendorState
          vendorStreet
          vendorZip
          waveDrinklinkFee
          createdAt
          updatedAt
        }
        usersID
        users {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDrinklinkAdmins = /* GraphQL */ `
  query GetDrinklinkAdmins($id: ID!) {
    getDrinklinkAdmins(id: $id) {
      id
      lastAccess
      role
      status
      usersID
      users {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDrinklinkAdmins = /* GraphQL */ `
  query ListDrinklinkAdmins(
    $filter: ModelDrinklinkAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDrinklinkAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lastAccess
        role
        status
        usersID
        users {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBartenders = /* GraphQL */ `
  query GetBartenders($id: ID!) {
    getBartenders(id: $id) {
      id
      fName
      lName
      otherIdentifier
      showOnRedeem
      vendorsID
      createdAt
      updatedAt
    }
  }
`;
export const listBartenders = /* GraphQL */ `
  query ListBartenders(
    $filter: ModelBartendersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBartenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fName
        lName
        otherIdentifier
        showOnRedeem
        vendorsID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendors = /* GraphQL */ `
  query GetVendors($id: ID!) {
    getVendors(id: $id) {
      id
      status
      vendorDisplayStatus
      vendorCity
      vendorContactPhone
      vendorEmail
      vendorImage
      vendorName
      vendorState
      vendorStreet
      vendorZip
      MenuItems {
        items {
          id
          description
          drink
          price
          vendorsID
          mainCategoriesID
          subCategoriesID
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      waveDrinklinkFee
      createdAt
      updatedAt
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFriends = /* GraphQL */ `
  query GetFriends($id: ID!) {
    getFriends(id: $id) {
      id
      usersID
      friendUserID
      friendUser {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFriends = /* GraphQL */ `
  query ListFriends(
    $filter: ModelFriendsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        usersID
        friendUserID
        friendUser {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByPhone = /* GraphQL */ `
  query UserByPhone(
    $phone: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        avatarImage
        birthDate
        email
        fName
        homeCity
        homeState
        isVendorUser
        lName
        lowerCaseUsername
        phone
        smsNotificationsAccept
        stripeCustomerId
        username
        emailNotificationsAccept
        userAskedToDelete
        deletedAt
        Friends {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const mainCategoriesByVendorsID = /* GraphQL */ `
  query MainCategoriesByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMainCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mainCategoriesByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        subCategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCategoriesByVendorsID = /* GraphQL */ `
  query SubCategoriesByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoriesByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subCategoriesByMainCategoriesID = /* GraphQL */ `
  query SubCategoriesByMainCategoriesID(
    $mainCategoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subCategoriesByMainCategoriesID(
      mainCategoriesID: $mainCategoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        MenuItems {
          nextToken
        }
        name
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuItemsByVendorsID = /* GraphQL */ `
  query MenuItemsByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        subCategoriesID
        subCategory {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuItemsByMainCategoriesID = /* GraphQL */ `
  query MenuItemsByMainCategoriesID(
    $mainCategoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByMainCategoriesID(
      mainCategoriesID: $mainCategoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        subCategoriesID
        subCategory {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuItemsBySubCategoriesID = /* GraphQL */ `
  query MenuItemsBySubCategoriesID(
    $subCategoriesID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsBySubCategoriesID(
      subCategoriesID: $subCategoriesID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        subCategoriesID
        subCategory {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuItemsByDrink = /* GraphQL */ `
  query MenuItemsByDrink(
    $type: String!
    $drink: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByDrink(
      type: $type
      drink: $drink
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        drink
        price
        vendorsID
        mainCategoriesID
        mainCategory {
          id
          name
          vendorsID
          createdAt
          updatedAt
        }
        subCategoriesID
        subCategory {
          id
          name
          vendorsID
          mainCategoriesID
          createdAt
          updatedAt
        }
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const menuItemsPurchasedByStripePaymentIntentID = /* GraphQL */ `
  query MenuItemsPurchasedByStripePaymentIntentID(
    $stripePaymentIntentID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemsPurchasedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsPurchasedByStripePaymentIntentID(
      stripePaymentIntentID: $stripePaymentIntentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        category
        personalMessage
        state
        stateHistory
        stripeStatus
        stripeStatusHistory
        stripeReceiptUrl
        drinkCost
        fees
        menuItemDescription
        menuItemName
        stripePaymentIntentID
        tip
        purchaserID
        purchaser {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        receiverID
        receiver {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        vendorsID
        vendor {
          id
          status
          vendorDisplayStatus
          vendorCity
          vendorContactPhone
          vendorEmail
          vendorImage
          vendorName
          vendorState
          vendorStreet
          vendorZip
          waveDrinklinkFee
          createdAt
          updatedAt
        }
        vendorNotes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vendorUsersByVendorsID = /* GraphQL */ `
  query VendorUsersByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorUsersByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        vendors {
          id
          status
          vendorDisplayStatus
          vendorCity
          vendorContactPhone
          vendorEmail
          vendorImage
          vendorName
          vendorState
          vendorStreet
          vendorZip
          waveDrinklinkFee
          createdAt
          updatedAt
        }
        usersID
        users {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vendorUsersByUsersID = /* GraphQL */ `
  query VendorUsersByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorUsersByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastAccess
        role
        status
        vendorsID
        vendors {
          id
          status
          vendorDisplayStatus
          vendorCity
          vendorContactPhone
          vendorEmail
          vendorImage
          vendorName
          vendorState
          vendorStreet
          vendorZip
          waveDrinklinkFee
          createdAt
          updatedAt
        }
        usersID
        users {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const drinklinkAdminsByUsersID = /* GraphQL */ `
  query DrinklinkAdminsByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDrinklinkAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    drinklinkAdminsByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastAccess
        role
        status
        usersID
        users {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const bartendersByVendorsID = /* GraphQL */ `
  query BartendersByVendorsID(
    $vendorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBartendersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bartendersByVendorsID(
      vendorsID: $vendorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fName
        lName
        otherIdentifier
        showOnRedeem
        vendorsID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vendorByCity = /* GraphQL */ `
  query VendorByCity(
    $vendorCity: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByCity(
      vendorCity: $vendorCity
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vendorByName = /* GraphQL */ `
  query VendorByName(
    $vendorName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByName(
      vendorName: $vendorName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vendorByState = /* GraphQL */ `
  query VendorByState(
    $vendorState: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVendorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vendorByState(
      vendorState: $vendorState
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        vendorDisplayStatus
        vendorCity
        vendorContactPhone
        vendorEmail
        vendorImage
        vendorName
        vendorState
        vendorStreet
        vendorZip
        MenuItems {
          nextToken
        }
        waveDrinklinkFee
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const friendsByUsersID = /* GraphQL */ `
  query FriendsByUsersID(
    $usersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFriendsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    friendsByUsersID(
      usersID: $usersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        usersID
        friendUserID
        friendUser {
          id
          avatarImage
          birthDate
          email
          fName
          homeCity
          homeState
          isVendorUser
          lName
          lowerCaseUsername
          phone
          smsNotificationsAccept
          stripeCustomerId
          username
          emailNotificationsAccept
          userAskedToDelete
          deletedAt
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
