import "./notfound.scss"
import * as React from 'react';
import { Link } from "react-router-dom";

const NotFound = ({listData}) => {
  return (
    <>
        <div className="notfound">
            Page Not Found 
        </div>
        <Link to="/">
            <div className="linkBackHome">Back to home</div>
        </Link>
    </>
  )
}

export default NotFound