import * as React from 'react';
import { useEffect, useContext, useState} from 'react';
import SidebarDrinklinkAdmin from "../../components/sidebar/SidebarDrinklinkAdmin";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
import DrinklinkAdminVendorsOwedDatatable from './drinklink-admin-vendors-owed-datatable'
const VendorsOwed = () => {
    return (
        <>
          <TopNavBar />
            <div className="list">
                <SidebarDrinklinkAdmin/>
                <div className="listContainer">
                  <DrinklinkAdminVendorsOwedDatatable />
                </div>
            </div>
          <Footer />
        </>
      )
}

export default VendorsOwed