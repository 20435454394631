import "./sidebar.scss";
import * as React from 'react';
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
//import { signOut } from 'firebase/auth';
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
//import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SportsBarIcon from '@mui/icons-material/SportsBar';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

//import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
//import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
//import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import { DarkModeContext } from "../../context/darkModeContext";
import { AuthContext } from '../../context/AuthContext';
import {VendorUserContext} from '../../context/VendorUserContext'

//import { auth } from "../../firebase";

const Sidebar = () => {
    const navigate = useNavigate();
    const { dispatch: authDispatch } = useContext(AuthContext);
    const { dispatch: vendorDispatch } = useContext(VendorUserContext);

    const handleLogout = (e) => {
        // signOut(auth)
        // .then(() => {
        //     authDispatch({ type: "LOGOUT", payload: null});
        //     vendorDispatch({type: "UNSET_USER_VENDORS", payload: null});
        //     vendorDispatch({type: "UNSET_USER_VENDOR_SELECTED", payload: null})
        //     navigate("/");
        // })
        // .catch(e => {
        //     console.log('error logging out: ', e);
        // }) 
    }

  const { dispatch } = useContext(DarkModeContext);
  return (
    <div className="sidebar">
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/vendorHome">
            <li>
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">Vendor Data</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/menuItems" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Menu Items</span>
            </li>
          </Link>
          <Link to="/editCats" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Menu Categories</span>
            </li>
          </Link>
          <Link to="/vendorInfo" style={{ textDecoration: "none" }}>
            <li>
                <CreditCardIcon className="icon" />
                <span>Vendor Info</span>
            </li>
          </Link>
          <Link to="/drinks" style={{ textDecoration: "none" }}>
            <li>
                <SportsBarIcon className="icon" />
                <span>Drinks</span>
            </li>
          </Link>
          <p className="title">Reports</p>
          <li>
            <InsertChartIcon className="icon" />
            <span>Stats</span>
          </li>
          <li>
            <CreditCardIcon className="icon" />
            <span>Sales</span>
          </li>
          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <Link to="/my-account" style={{ textDecoration: "none" }}>
              <span>My Profile</span>
            </Link>
            
          </li>
        <li>
            <ExitToAppIcon className="icon" />
            <span onClick={() => handleLogout()}>Logout</span>
        </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
