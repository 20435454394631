import "./vendors-owed-datatable.scss";
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import styled from 'styled-components';
import { useEffect, useState, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { AuthContext } from "../../context/AuthContext";
import { RotatingLines } from 'react-loader-spinner';
import {
    listMenuItemsPurchaseds
} from "../../../src/graphql/queries"
import {drinkColumns} from './vendorsOwedDatatableSource'

const VendorsOwedListWrap = styled.div`
    font-size: 24px;
    margin: 30px 30px;
    display: flex;
    flex-direction: column;
    border: 1px solid grey;
    border-radius: 10px;
`
const VendorWrap = styled.div`
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`
const SelectVendorButton = styled.div`
    margin-left: 30px;
    padding: 2px 5px;
    border-radius: 5px;
    color: darkblue;
    border: 1px dotted rgba(0, 0, 139, 0.596);
    cursor: pointer;
`
const VendorAddressWrap = styled.div`
    margin-left: 20px;
    font-size: 18px;
`
const ActionButtonsWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`
const DrinkTotalSection = styled.div`
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
`

const DrinklinkAdminVendorsOwedDatatable = () => {
    const [data, setData] = useState([]);
    const [drinkData, setDrinkData] = useState([])
    const [vendorDetailsVendor, setVendorDetailsVendor] = useState()
    const [owedVendors, setOwedVendors] = useState([])
    const [view, setView] = useState('vendors')
    const [isLoading, setIsLoading] = useState(true);
    const [totalOwed, setTotalOwed] = useState()
    const {currentUser} = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async () => {
            // get all drinks in redeemed state.
            const menuItemsPurchasedReturn = await API.graphql(graphqlOperation(
                listMenuItemsPurchaseds, {
                    filter: {
                        state: {eq: 'redeemed'}
                    }
                }
            ))
            let menuItemsPurchased = menuItemsPurchasedReturn.data.listMenuItemsPurchaseds.items
            aggregateVendors(menuItemsPurchased);
            setDrinkData(menuItemsPurchased);
            console.log('menuItemsPurchased', menuItemsPurchased);
            // aggregate that data by vendorsID
            // set vendors as the data
            setIsLoading(false)
        }
        fetchData()
        
    }, [])

    const aggregateVendors = (menuItemsPurchased) => {
        let vendorsIdList = []
        let theVendors = []
        for (const item of menuItemsPurchased) {
            const found = vendorsIdList.find((itm) => itm === item.vendor.id)
            if (!found) {
                theVendors.push(item.vendor)
                vendorsIdList.push(item.vendor.id);
            }
        }
        setOwedVendors(theVendors)
    }

    const handleShowVendorDetails = (vendor) => {
        // load drinks for this vendor into data
        const vendorDrinks = []
        let runningTotal = 0;
        for (const dr of drinkData) {
            if (dr.vendor.id === vendor.id) {
                vendorDrinks.push(dr)
                runningTotal = runningTotal + dr.drinkCost + dr.tip
            }
        }
        setTotalOwed(runningTotal)
        setData(vendorDrinks)
        setVendorDetailsVendor(vendor);
        setView('details')
    }

    const displayVendor = (vendor) => {
        console.log('vendor', vendor);
        return (
            <VendorWrap>
                <div>{vendor.vendorName}</div>
                <VendorAddressWrap>
                    <div>{vendor.vendorCity}, {vendor.vendorState}</div>
                </VendorAddressWrap>
                <SelectVendorButton
                    onClick={() => handleShowVendorDetails(vendor)}
                >View Details</SelectVendorButton>
            </VendorWrap>
        )
    }

    const dispalyVendorsList = () => {
        return (
            <VendorsOwedListWrap>
                {owedVendors.map((vend) => displayVendor(vend))}
            </VendorsOwedListWrap>
        )
    }

    const onSetDrinksPaid = () => {
        alert('need to implement still');
    }

    console.log('currentUser', currentUser)

    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    Vendors Owed
                    {view === 'details' && <ActionButtonsWrap>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => setView('vendors')} className="link">
                                Back to Vendors
                            </div>
                        </div>
                        <div className="dataTableTitleButtons">
                            <div onClick={() => onSetDrinksPaid()} className="link">
                                Set Drinks to Vendor Paid
                            </div>
                        </div>
                    </ActionButtonsWrap>}
                    {/* { userVendorSelected.role !== 'viewer' && 
                        <div className="dataTableTitleButtons">
                            <div onClick={() => handleAddUser(null, 'add-user')} className="link">
                                Add New User
                            </div>
                        </div>
                    } */}
                    <div className="dataTableSearch">
                        {/* <Searchbar
                            style={{fontSize: 20}}
                            icon="account-search"
                            placeholder={'Enter name or email'}
                            value={searchText}
                            onChangeText={(txt) => {
                                onChangeSearchText(txt)
                            }}
                        /> */}
                    </div>
                </div>
                {!isLoading ?
                    <>
                        {view === 'vendors' ?
                            <div>{dispalyVendorsList()}</div>
                            :
                            <>
                                <DrinkTotalSection>Drinklink owes {vendorDetailsVendor.vendorName}: ${totalOwed}</DrinkTotalSection>
                                <DataGrid
                                    className="datagrid"
                                    rows={data}
                                    columns={drinkColumns}
                                    pageSize={9}
                                    rowsPerPageOptions={[25]}
                                    //checkboxSelection
                                />
                            </>
                        }
                    </>
                    
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            {/* {removeUserConfirmModalOpen &&
                <RemoveUserConfirmModal 
                    modalState={removeUserConfirmModalOpen}
                    handleModalClose={handleRemoveUserConfirmModalClose}
                    handleFormSubmit={handleUserRemove}
                    editingUser={selectedUserToEdit}
                />
            } */}
        </>
    );
}

export default DrinklinkAdminVendorsOwedDatatable