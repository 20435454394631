import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {DarkModeContextProvider} from "./context/darkModeContext";
import {AuthContextProvider} from './context/AuthContext';
import {VendorUserContextProvider} from "./context/VendorUserContext";
import {DrinklinkAdminContextProvider} from "./context/DrinklinkAdminContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <AuthContextProvider>
        <DrinklinkAdminContextProvider>
          <VendorUserContextProvider>
            <App />
          </VendorUserContextProvider>
        </DrinklinkAdminContextProvider>
      </AuthContextProvider>
    </DarkModeContextProvider>
  </React.StrictMode>
);
