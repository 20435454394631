import * as React from 'react';
import "./landing.scss";

const Footer = () => {
    return (
        <div className="landing-footer-container">
            <div className="landing-footer">
                Footer
            </div>
        </div>
    )
}

export default Footer;