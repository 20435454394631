import "./users-datatable.scss";
import * as React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { useEffect, useState, useContext } from "react";
import {API, graphqlOperation} from "aws-amplify";

import {
    updateUsers,
    createDrinklinkAdmins,
    updateDrinklinkAdmins,
    deleteDrinklinkAdmins
} from "../../../src/graphql/mutations"
import {
    listUsers,
    listDrinklinkAdmins
} from "../../../src/graphql/queries"
import { RotatingLines } from 'react-loader-spinner'

import AddUserModal from "./add-user-modal";
import EditDrinklinkAdminUserRoleModal from "./edit-drinklink-admin-user-role-modal";
import { userColumns } from "./drinklinkAdminUserDataTablesource";
import { VendorUserContext } from "../../context/VendorUserContext";
import { AuthContext } from "../../context/AuthContext"
import RemoveUserConfirmModal from "./remove-user-confirm-modal";

const DrinklinkAdminUsersDatatable = () => {

    const [data, setData] = useState([]);
    const [editUserRoleModalOpen, setEditUserRoleModalOpen] = useState(false);
    //const [removeUserConfirmModalOpen, setRemoveUserConfirmModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dlAdmins, setDlAdmins] = useState([])
    const [selectedUserToEdit, setSelectedUserToEdit] = useState();
    //const { userVendorSelected } = useContext(VendorUserContext);
    const { currentUser } = useContext(AuthContext);


    const handleEditUserRoleModalClose = () => {
        setEditUserRoleModalOpen(false);
    }

    // const handleRemoveUserConfirmModalClose = () => {
    //     setRemoveUserConfirmModalOpen(false);
    // }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersRet = await API.graphql(graphqlOperation(
                    listUsers, {
                        filter: {}
                    }
                ))
                const allUsers = usersRet.data.listUsers.items;
                console.log('allUsers', allUsers)

                const drinklinkAdmins = await API.graphql(graphqlOperation(listDrinklinkAdmins), {
                    filter: {}
                })
                const drAdmins = drinklinkAdmins.data.listDrinklinkAdmins.items;
                setDlAdmins(drAdmins)
                console.log('drAdmins', drAdmins)

                const newUsers = allUsers.map((usr) => {
                    const foundAdmin = drAdmins.findIndex((adm) => adm.usersID === usr.id);
                    if (foundAdmin > -1) {
                        return {
                            ...usr,
                            drinklinkUserRole: drAdmins[foundAdmin].role,
                            drinklinkUserStatus: drAdmins[foundAdmin].status
                        }
                    }
                    return usr
                })
                
                console.log('newUsers', newUsers);
                setData(newUsers);
                setIsLoading(false);
            } catch (err) {
                console.log('err', err)
                setIsLoading(false);
            }
        };

        if (currentUser) {
            fetchData();
        }
        

    }, [currentUser]);

    const handleSelectEditRole = (rowData) => {
        setSelectedUserToEdit({userId: rowData.id, userRole: rowData.drinklinkUserRole});
        setEditUserRoleModalOpen(true);
    }

    const handleEditUserRoleFormSubmit = async (userId, userRole) => {
        const theAdmin = dlAdmins.find((adm) => adm.usersID === userId.userId);
        const theUserIndex = data.findIndex((usr) => usr.id === userId.userId);
        const newData = [...data];
        try {
            if (userRole === 'remove-as-admin-user') {
                if (theAdmin) {
                    await API.graphql(graphqlOperation(deleteDrinklinkAdmins, {input: {id: theAdmin.id}}))
                }
                delete newData[theUserIndex].drinklinkUserRole
            } else {
                if (theAdmin) {
                    // already and admin, so update
                    const theInput = {
                        id: theAdmin.id,
                        role: userRole
                    }
                    await API.graphql(graphqlOperation(updateDrinklinkAdmins,
                        {input: theInput}
                    ))
                    newData[theUserIndex].drinklinkUserRole = userRole
                } else {
                    // create new entry with role
                    const theInput = {
                        role: userRole,
                        status: 'active',
                        usersID: userId.userId
                    }
                    await API.graphql(graphqlOperation(createDrinklinkAdmins,
                        {input: theInput}
                    ))
                    newData[theUserIndex].drinklinkUserRole = userRole
                    
                }
            }
            // add to data...
            setData(newData);
            handleEditUserRoleModalClose();
        } catch (e) {
            console.log('error updating role: ', e);
        }
        
    }

    // const handleUserRemove = async () => {
    //     try {
    //         const docRef = doc(db, '/vendorUsers', selectedUserToEdit.vendorUserId);
    //         await deleteDoc(docRef);
    //         let updatedData = [...data];
    //         updatedData = updatedData.filter(item => item.id !== selectedUserToEdit.userId);
    //         setData(updatedData);
    //         handleRemoveUserConfirmModalClose();
    //     } catch (e) {
    //         console.log('error removing user from this vendor', e);
    //     }
    //     // do alert to confirm.
    // }

    const handleMakeUserVendor = async (userData) => {
        try {
            const updateInput = {
                id: userData.id,
                isVendorUser: !userData.isVendorUser
            }
            await API.graphql(graphqlOperation(updateUsers, {input: updateInput}))
            const theseUsers = [...data]
            const userIndex = theseUsers.findIndex((usr) => usr.id === userData.id)
            theseUsers[userIndex] = {...userData, isVendorUser: updateInput.isVendorUser }
            setData(theseUsers);
        } catch (e) {
            console.log('error updating user', e)
        }
    }

    // const handleSelectUserToRemove = (rowData) => {
    //     setSelectedUserToEdit(rowData);
    //     setRemoveUserConfirmModalOpen(true);
    // }

    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: window.location.href.includes('/menuItems') ? 200 : 600,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <div 
                            className="viewButton"
                            onClick={() => handleSelectEditRole(params.row)}
                        >   
                            Edit/Add Drinklink Admin Role
                        </div>
                        {/*<div 
                            className="viewButton"
                            //onClick={() => handleUserEdit(params.row, 'resend-invite')}
                        >   
                            Resend Invite
                </div> */}
                        {/* <div
                            className="deleteButton"
                            onClick={() => handleSelectUserToRemove(params.row)}
                        >
                            Remove
                        </div> */}
                        <div
                            className="deleteButton"
                            onClick={() => handleMakeUserVendor(params.row)}
                        >
                            {params.row.isVendorUser? 'Remove Vendor User' : 'Make Vendor User'}
                        </div>
                    </div>
                );
            },
        },
    ];
    return (
        <>
            <div className="datatable">
                <div className="datatableTitle">
                    All Users
                    {/* { userVendorSelected.role !== 'viewer' && 
                        <div className="dataTableTitleButtons">
                            <div onClick={() => handleAddUser(null, 'add-user')} className="link">
                                Add New User
                            </div>
                        </div>
                    } */}
                    <div className="dataTableSearch">
                        {/* <Searchbar
                            style={{fontSize: 20}}
                            icon="account-search"
                            placeholder={'Enter name or email'}
                            value={searchText}
                            onChangeText={(txt) => {
                                onChangeSearchText(txt)
                            }}
                        /> */}
                    </div>
                </div>
                {!isLoading ? 
                    <DataGrid
                        className="datagrid"
                        rows={data}
                        columns={currentUser.drinklinkUser.role !== 'viewer' ? userColumns.concat(actionColumn) : userColumns}
                        pageSize={9}
                        rowsPerPageOptions={[25]}
                        //checkboxSelection
                    />
                    :
                    <div className='loading-gif'>
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="96"
                            visible={true}
                        />
                    </div>
                }
            </div>
            {/* {addUserModalOpen &&
                <AddUserModal
                    modalState={addUserModalOpen}
                    handleModalClose={handleAddUserModalClose}
                    handleFormSubmit={handleAddUserFormSubmit}
                    isExistingUser={existingUser}
                    isNotAMember={!existingMember}
                    handleSendMemberInvite={handleSendMemberInvite}
                    handleSendUserNotification={handleSendUserNotification}
                />
            } */}
            {editUserRoleModalOpen &&
                <EditDrinklinkAdminUserRoleModal 
                    modalState={editUserRoleModalOpen}
                    handleModalClose={handleEditUserRoleModalClose}
                    handleFormSubmit={handleEditUserRoleFormSubmit}
                    editingUser={selectedUserToEdit}
                />
            }
            {/* {removeUserConfirmModalOpen &&
                <RemoveUserConfirmModal 
                    modalState={removeUserConfirmModalOpen}
                    handleModalClose={handleRemoveUserConfirmModalClose}
                    handleFormSubmit={handleUserRemove}
                    editingUser={selectedUserToEdit}
                />
            } */}
        </>
    );
}

export default DrinklinkAdminUsersDatatable;