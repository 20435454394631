import * as React from 'react';
import { useContext } from "react";
import styled from "styled-components";

import { VendorUserContext } from "../../context/VendorUserContext";

const VendorHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 150px;
`;

const VendorImage = styled.div`
  display: flex;
  margin-left: 20px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
`

const VendorDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const VendorDetailsName = styled.div`
    font-size: 22px;
    font-weight: Bold;
`;

const VendorDetailsAddress = styled.div`
    font-size: 18px;
`

const VendorIMG = styled.img`
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    border-radius: 10px;
`


const VendorNameBlock = () => {
    const { userVendorSelected } = useContext(VendorUserContext);

    console.log(userVendorSelected)
    // useEffect(() => {
    //     //const imageUri = await Storage.get(result.key);
    // }, [])
    return (
        <VendorHeaderWrapper>
            {userVendorSelected.vendorImage && 
            <VendorImage>
                <VendorIMG src={userVendorSelected.vendorImageUrl} style={{height: "125px"}} alt="vendor"/>
            </VendorImage>}
            <VendorDetails>
                <VendorDetailsName>{userVendorSelected.vendorName}</VendorDetailsName>
                <VendorDetailsAddress>{userVendorSelected.vendorCity}, {userVendorSelected.vendorState}</VendorDetailsAddress>
            </VendorDetails>
        </VendorHeaderWrapper>
    )
}

export default VendorNameBlock;