//import "./list.scss"
import * as React from 'react';
import { useState, useEffect, useContext, useMemo } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { listMainCategories } from "../../graphql/customQueries";
import Sidebar from "../../components/sidebar/Sidebar";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
import VendorNameBlock from "../home/VendorNameBlock";
import EditCategoriesComponent from '../../components/vendorAdmin/editCategoriesComponent';
import EditSubCategoriesComponent from '../../components/vendorAdmin/editSubCategoriesComponent';
import { VendorUserContext } from "../../context/VendorUserContext";

const EditCategories = () => {
    const [categories, setCategories] = useState()
    const [catSelected, setCatSelected] = useState('none')
    const { userVendorSelected } = useContext(VendorUserContext);

    const getCategories = async () => {
        const categoriesDataReturn = await API.graphql(graphqlOperation(listMainCategories, {
            filter: {
                vendorsID: {eq: userVendorSelected.id}
            }
        }));
        let categoriesData = categoriesDataReturn.data.listMainCategories.items;
        setCategories(categoriesData);
    }

    useEffect(() => {
        getCategories()
    }, [])
    return (
        <>
            <TopNavBar />
            <div className="list">
                <Sidebar/>
                <div className="listContainer">
                    <VendorNameBlock />
                    {catSelected === 'none' ?
                        <EditCategoriesComponent 
                            categoriesIn={categories}
                            setCatSelected={setCatSelected}
                            setCategories={setCategories}
                        />
                        :
                        <EditSubCategoriesComponent 
                            categoriesIn={categories}
                            setCatSelected={setCatSelected}
                            setCategories={setCategories}
                            cat={catSelected}
                        />
                    }
                </div>
            </div>
            <Footer />
        </>
    )
}

export default EditCategories