import * as React from 'react';
import { useState } from "react";
import styled from 'styled-components';

const CategoryItemWrap = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 10px 20px;
    border: 1px solid grey;
    border-radius: 10px;
`
const CategoryTitle = styled.div`
`
const CategoryButtonsRow = styled.div`
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: space-between;
`
const CategoryButtonDelete = styled.div`
    padding: 2px 5px;
    border: 1px solid red;
    border-radius: 5px;
    color: red;
    cursor: pointer;
`
const CategoryButtonEdit = styled.div`
    padding: 2px 5px;
    border: 1px solid blue;
    border-radius: 5px;
    color: blue;
    cursor: pointer;
`

const NewItemBox = ({
    handleSaveItem,
    placeholderText,
    type
}) => {
  const [itemName, setItemName] = useState()

    const handleInputChange = (event) => {
        const value = event.target.value
        setItemName(value)
    }

    const handleSaveItemClick = (itemName, type) => {
        handleSaveItem(itemName, 'addsubs')
        setItemName('')
    }

    return (
        <CategoryItemWrap>
            <CategoryTitle>
                <div className="formInput" key="vendorName">
                    <label>Name</label>
                    <input 
                        type='text' 
                        placeholder={`${placeholderText}`}
                        name='newItem'
                        value={itemName}
                        onChange={handleInputChange}
                    />
                </div>
        </CategoryTitle>
        <CategoryButtonsRow>
            {type === 'main' && <CategoryButtonDelete onClick={() => handleSaveItemClick(itemName, 'addsubs')}>
                Save and Add Subs
            </CategoryButtonDelete>}
            <CategoryButtonEdit
                onClick={() => handleSaveItemClick(itemName, 'done')}
            >
                Save
            </CategoryButtonEdit>
        </CategoryButtonsRow>
        </CategoryItemWrap>
    )
}

export default NewItemBox