import {NumericFormat} from 'react-number-format';
import {formatMoney} from '../../utils/utils';

export const drinkCategoryTypes = [
    "beer",
    "cider",
    "liquor",
    "cocktail",
    "non-alcoholic",
    "other"
];

export const drinkColumns = [
    {
        field: "menuItemName",
        headerName: "Drink",
        width: 230,
    },
    {
        field: "drinkCost",
        headerName: "Price",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NumericFormat value={formatMoney(params.row.drinkCost).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            );
        },
    },
    {
        field: "tip",
        headerName: "Tip",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NumericFormat value={formatMoney(params.row.tip).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            );
        },
    },
    {
        field: "total",
        headerName: "Total",
        width: 100,
        renderCell: (params) => {
            return (
                <div>
                    <NumericFormat value={formatMoney(params.row.tip + params.row.drinkCost).toString()} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            );
        },
    },
    {
        field: "status",
        headerName: "Status",
        width: 120,
        renderCell: (params) => {
            const thisClassname = `${params.row.status}Drink`;
            return (
                <div
                    className={thisClassname}
                >
                    {params.row.status}
                </div>
            );
        },
    },
    {
        field: "statusDate",
        headerName: "Status Date",
        width: 200,
    },

];