import * as React from 'react'

export const vendorLinks = {

}

export const vendorColumns = [
    {
        field: "vendorName",
        headerName: "Name",
        width: 200
    },
    {
        field: "vendorStreet",
        headerName: "Street",
        widht: 300
    },
    {
        field: "vendorCity",
        headerName: "City",
        width: 100
    },
    {
        field: "vendorState",
        headerName: "State",
        width: 40
    },
    {
        field: "vendorZip",
        headerName: "Zip",
        width: 130
    },
    {
        field: "waveDrinklinkFee",
        headerName: "Wave Fee",
        width: 100
    },
    {
        field: "status",
        headerName: "DL Status",
        width: 80
    },
    {
        field: "vendorDisplayStatus",
        headerName: "Live status",
        width: 130
    }

]