import * as React from 'react';
import { useState, useCallback, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Select from 'react-select'
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import "./edit-vendor-modal.scss";
import { stateData } from '../../utils/state-data';
import { isValidEmail } from '../../utils/utils';
import usePhoneNumber from '../../hooks/usePhoneNumber';

const FormHeader = styled.div`
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
`;
const FormRequired = styled.div`
    width: 50%;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: left;
`;

const ErrorTextWrapper = styled.div`
    color: red;
    font-size: 14px;
    font-weight: normal;
`;

const DrinklinkAdminEditVendorModal = ({modalState, handleModalClose, handleFormSubmit, editingVendor}) => {
    const {
        phoneNumber,
        toOriginal,
        onNumberChange,
    } = usePhoneNumber(editingVendor?.vendorContactPhone ? editingVendor.vendorContactPhone : '')
    const [formData, setFormData] = useState({
        vendorName: editingVendor?.vendorName ? editingVendor.vendorName : '',
        vendorStreet: editingVendor?.vendorStreet ? editingVendor.vendorStreet : '',
        vendorCity: editingVendor?.vendorCity ? editingVendor.vendorCity : '',
        vendorZip: editingVendor?.vendorZip ? editingVendor.vendorZip : '',
        vendorEmail: editingVendor?.vendorEmail ? editingVendor.vendorEmail : '',
        vendorState: editingVendor?.vendorState ? editingVendor.vendorState : null,
        //vendorContactPhone: editingVendor?.vendorContactPhone ? editingVendor.vendorContactPhone : '',
        vendorImage: editingVendor?.vendorImage ? editingVendor.vendorImage : null,
        status: editingVendor?.status ? editingVendor.status : null,
        vendorDisplayStatus: editingVendor?.vendorDisplayStatus ? editingVendor.vendorDisplayStatus : 'hidden',
        waveDrinklinkFee: editingVendor?.waveDrinklinkFee ? editingVendor.waveDrinklinkFee : false
    });
    const [isComplete, setIsComplete] = useState(false);
    const [theState, setTheState] = useState(editingVendor?.vendorState ? editingVendor?.vendorState : null);
    //const [number, setNumber] = useState(editingVendor?.vendorContactPhone ? editingVendor.vendorContactPhone : '');
    const [errorMessage, setErrorMessage] = useState([]);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);
    const [waiveFee, setWaiveFee] = useState(editingVendor.waveDrinklinkFee ? editingVendor.waveDrinklinkFee : false)
    //const [vendorDisplayStatus, setVendorDisplayStatus] = useState(editingVendor.vendorDisplayStatus ? editingVendor.vendorDisplayStatus : 'hidden')


    useEffect(() => {
        setErrorMessage([]);
        if (
            formData.vendorName !== '' 
            && formData.vendorStreet && formData.vendorStreet !== '' 
            && formData.vendorCity && formData.vendorCity !== '' 
            && theState && theState !== '' 
            && formData.vendorZip && formData.vendorZip !== '' 
            && formData.vendorEmail && formData.vendorEmail !== ''
            && formData.vendorState && formData.vendorState !== ''
            && phoneNumber && phoneNumber !== '') {
            setIsComplete(true);
        }
    }, [formData, theState, phoneNumber])

    const handleSaveVendor = () => {
        if (!isValidEmail(formData.vendorEmail)) {
            return;
        }
        const number = toOriginal(phoneNumber);
        if (number.length !== 10) {
            setPhoneError('Phone number is not valid');
            return;
        }
    
        const sendData = {
            ...formData,
            vendorContactPhone: number,
            vendorState: theState
        }
        handleFormSubmit(sendData)
    }

    const handleNumberChange = (event) => {
        const phone = event.target.value;
        setPhoneError(null);
        onNumberChange(phone);
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (event.target.name === 'vendorEmail' && !isValidEmail(value)) {
            setEmailError('Email is not valid');
        } else {
            setEmailError(null)
        } 
        
        setFormData({
            ...formData,
            [event.target.name] : value
        })
    }

    const handleWaiveFeeChange = (event) => {
        console.log('event', event)
        setWaiveFee(event.target.checked)
        setFormData({
            ...formData,
            waveDrinklinkFee: event.target.checked
        })
    }

    const handleStateChange = (selectedOption) => {
        setTheState(selectedOption.value);
        setFormData({
            ...formData,
            vendorState: selectedOption.value
        })
    }

    const handleDrinklinkStatusChange = (selectedOption) => {
        setFormData({
            ...formData,
            status: selectedOption.value
        })
    }

    const handleVisibilityChange = (selectedOption) => {
        setFormData({
            ...formData,
            vendorDisplayStatus: selectedOption.value
        })
    }

    const drinklinkSates = [
        {
            value: 'approved',
            label: 'Approved'
        },
        {
            value: 'created',
            label: 'Created'
        },
        {
            value: 'rejected',
            label: 'Rejected'
        }
    ]
    const visibilityStates = [
        {
            value: 'visible',
            label: 'Visible'
        },
        {
            value: 'hidden',
            label: 'Hidden'
        },
    ]

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-vendor-wrapper'>
                <FormHeader>Fill out new vendor information</FormHeader>
                <FormRequired>** All Fields are required</FormRequired>
                <form>
                    <div className="formInput" key="vendorName">
                        <label>Name</label>
                        <input 
                            type='text' 
                            placeholder="name"
                            name='vendorName'
                            value={formData.vendorName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key="vendorStatus">
                        <label>Drinklink Status:</label>
                        <Select 
                            options={drinklinkSates} 
                            name="status"
                            onChange={handleDrinklinkStatusChange}
                            defaultValue={drinklinkSates.find(opt => opt.value === formData.status)}
                        />
                    </div>
                    <div className="formInput" key="vendorDisplayStatus">
                        <label>Visibility:</label>
                        <Select 
                            options={visibilityStates} 
                            name="vendorDisplayStatus"
                            onChange={handleVisibilityChange}
                            defaultValue={visibilityStates.find(opt => opt.value === formData.vendorDisplayStatus)}
                        />
                    </div>
                    <div className="formInput" key="waveDrinklinkFee">
                        <FormControlLabel control={<Checkbox onChange={handleWaiveFeeChange} checked={waiveFee} />} label="Waive DrinkLink Fee" />
                    </div>
                    <div className="formInput" key="vendorEmail">
                        <label>Contact Email</label>
                        <input 
                            type='text' 
                            placeholder="email"
                            name='vendorEmail'
                            value={formData.vendorEmail}
                            onChange={handleInputChange}
                        />
                        {emailError && <ErrorTextWrapper>{emailError}</ErrorTextWrapper>}
                    </div>
                    <div className="formInput" key="vendorStreet">
                        <label>Street</label>
                        <input 
                            type='text' 
                            placeholder="123 some st"
                            name='vendorStreet'
                            value={formData.vendorStreet}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key="vendorCity">
                        <label>City</label>
                        <input 
                            type='text' 
                            placeholder="city"
                            name='vendorCity'
                            value={formData.vendorCity}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key="vendorState">
                        <label>State: {formData.vendorState.toUpperCase()}</label>
                        <Select 
                            options={stateData} 
                            name="vendorState"
                            //value={stateData.find(opt => opt.value.toLowerCase() === formData.vendorState?.toLowerCase())}
                            onChange={handleStateChange}
                            defaultValue={stateData.find(opt => opt.value === formData.vendorState.toUpperCase())}
                        />
                    </div>
                    <div className="formInput" key="vendorZip">
                        <label>Vendor Zip</label>
                        <input 
                            type='text' 
                            placeholder="12345"
                            name='vendorZip'
                            value={formData.vendorZip}
                            onChange={handleInputChange}
                        />
                    </div>
                    
                    <div className="formInput" key="vendorContactPhone">
                        <label>Contact Phone</label>
                        <input
                            type='text'
                            value={phoneNumber}
                            onChange={handleNumberChange}
                            placeholder="Enter Phone Number"
                        />
                        {phoneError && <ErrorTextWrapper>{phoneError}</ErrorTextWrapper>}
                    </div>
                    
                    
                    {errorMessage.length > 0 && 
                        <div className='edit-modal-error-message-wrap'>
                            {errorMessage.map(mes => <div className='edit-message-error-message'>Error: {mes}</div>)}
                        </div>
                    }

                    <div className='edit-modal-button-row'>
                        <button type="button" onClick={handleModalClose} >Cancel</button>
                        <button type='button' disable={(!isComplete).toString()} className={!isComplete ? "disabled" : null} onClick={() => handleSaveVendor()}>Save Vendor</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default DrinklinkAdminEditVendorModal;
