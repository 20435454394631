import * as React from 'react';
import { useState } from "react";
import Modal from '@mui/material/Modal';
import "./drinks-notes-modal.scss";

const DrinkNotesModal = ({modalState, theDrink, handleModalClose, handleNoteSubmit}) => {
    const [theNote, setTheNote] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;  
        setTheNote(value);
    }

    const handleSaveMenuItem = () => {
        const dateSaved = new Date().getTime();
        const existingNotes = theDrink.vendorNotes ? JSON.parse(theDrink.vendorNotes) : []
        const notes = [...existingNotes];
        const newNote = {
            date: dateSaved,
            note: theNote
        }
        notes.push(newNote);
        handleNoteSubmit(notes, theDrink.id);
    }

    // const aDate = new Date("2022-01-29T23:00:00").getTime();
    return (
         <Modal
             open={modalState}
             onClose={handleModalClose}
             aria-labelledby="modal-modal-title"
             aria-describedby="modal-modal-description"
         >
             <div className='drink-notes-wrapper'>
                <div className='drink-notes-heading'>
                    Add Note
                </div>
                <form>
                    <div className="formInput" key='addNote'>
                        <textarea
                            rows="5"
                            cols="40" 
                            placeholder='drink notes'
                            name="notes"
                            value={theNote}
                            onChange={handleInputChange}
                        >
                        </textarea>
                    </div>
                    <div className='edit-modal-button-row'>
                        <button type="button" onClick={handleModalClose} >Cancel</button>
                        <button type='button' onClick={() => handleSaveMenuItem()}>Save Note</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default DrinkNotesModal;