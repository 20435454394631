import * as React from 'react';
import { useState, useEffect, useContext, useMemo } from "react";
import {API, graphqlOperation} from "aws-amplify";
import { VendorUserContext } from "../../context/VendorUserContext";

import styled from 'styled-components';
import {Spinner} from 'react-bootstrap';
import CategoryBox from './categoryBoxComponent';
import NewItemBox from './newItemBoxComponent';
import { 
    createMainCategories,
    deleteMainCategories
} from "../../../src/graphql/mutations"

const PageWrap = styled.div`
    margin: 40px 40px;
    display: flex;
    flex-direction: column;
`

const NavWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
`
const NavItem = styled.div`
`

const CatsBoxWrap = styled.div`
    display: flex;
`

const CatsBoxContent = styled.div`
    margin: 40px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`
const EditCategoriesComponent = ({
    categoriesIn,
    setCatSelected,
    setCategories
}) => {
    const { userVendorSelected } = useContext(VendorUserContext);
    console.log('categoiresIn', categoriesIn)

    const handleSaveNewCategory = async(newCategory, type) => {
        console.log('newCategory', newCategory)
        console.log('type', type)
        const newInput = {
            name: newCategory,
            vendorsID: userVendorSelected.id,
        }
        const addedCategory = await API.graphql(graphqlOperation(createMainCategories, {input: newInput}))
        const newMainCategory = addedCategory.data.createMainCategories
        const theseCategories = [...categoriesIn];
        theseCategories.push(newMainCategory)
        setCategories(theseCategories)
        // save category to db use result
        // use setCategories to add to categories list
        if (type === 'addsubs') {
            setCatSelected(newMainCategory)
        }
    }

    const handleDeleteCategory = async (deleteCat) => {
        try {
            const theseCategories = [...categoriesIn];
            // delete all subs for this category in db. We don't want orphaned subs.
            const foundMain = theseCategories.findIndex((cat) => cat.id === deleteCat.id);
            const theseSubs = theseCategories[foundMain].subCategories.items;
            if (theseSubs.length > 0) {
                const subsMutation = theseSubs.map((itm, i) => {
                    return `mutation${i}: deleteSubCategories(input: {id: "${itm.id}"}) { id }`;
                });
                await API.graphql(
                    graphqlOperation(`
                    mutation batchMutation {
                        ${subsMutation}
                        }
                    `)
                );
            }
            
            // now delete main category.
            await API.graphql(graphqlOperation(deleteMainCategories, {input: {id: deleteCat.id}}))

            // now update categories state removing this one and its subs
            const filterResult = theseCategories.filter((cat) => deleteCat.id !== cat.id)
            setCategories(filterResult)
        } catch (e) {
            console.log('error deleting main category', e)
        }
    }
  
    const displayCat = (cat) => {
        return (
            <CategoryBox 
                cat={cat}
                setCatSelected={setCatSelected}
                handleDeleteCategory={handleDeleteCategory}
            />
        )
    }

    const displayCats = () => {
        return (
            <>
                <NewItemBox
                    handleSaveItem={handleSaveNewCategory}
                    placeholderText='new category'
                    type='main'
                />
                {categoriesIn.map((cat) => displayCat(cat))}
            </>
        )
    }

    return (
      <PageWrap>
        <NavWrap>
            <NavItem>Categories</NavItem>
        </NavWrap>
        <CatsBoxWrap>
            {categoriesIn ? 
                <>
                    <CatsBoxContent>{displayCats()}</CatsBoxContent>
                </>
                :
                <Spinner></Spinner>
            }
        </CatsBoxWrap>
      </PageWrap>
    )
}

export default EditCategoriesComponent
