import * as React from 'react';
import styled from 'styled-components';

import TopNavBar from '../Landing/topNavBar';
import Footer from '../Landing/footer';
import MyDetails from '../../components/account/myDetails';

const MyAccount = () => {
    return (
        <>
            <TopNavBar />
                <MyDetails />
            <Footer />
        </>
    )
}

export default MyAccount;