import * as React from 'react';
import { useState } from "react";
import Modal from '@mui/material/Modal';
import "./add-user-modal.scss";
import Select from 'react-select'

import {userRoles} from '../../utils/utils';


const EditUserRoleModal = ({
    modalState, 
    handleModalClose, 
    handleFormSubmit, 
    currentUserId,
    editingUser
}) => {
    console.log('currentUser', editingUser);
    const [userRole, setUserRole] = useState(editingUser.userRole);


    const handleRoleChange = (selectedOption) => {
        setUserRole(selectedOption.value);
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-user-wrapper'>
                    <form>
                        <div className="role-descriptions">
                            Select a role for this user
                            <div className="role-description">
                                Super Admin: Can edit and view all things
                            </div>
                            <div className="role-description">
                                Admin: Can edit menu items and view all things
                            </div>
                            <div className="role-description">
                                Viewer: Can view all things
                            </div>
                        </div>

                        <div className="formInput" key='userRoleInput'>
                            <label>Role</label>
                            <Select 
                                options={userRoles} 
                                name="userRoleSelect"
                                //value={userRoles.find(opt => opt.value === userRole)}
                                onChange={handleRoleChange}
                                defaultValue={userRoles.find(opt => opt.value === userRole)}
                            />
                        </div>
                        <div className='edit-modal-button-row'>
                            <button type="button" onClick={handleModalClose} >Cancel</button>
                            <button type='button' onClick={() => handleFormSubmit(editingUser, userRole)}>Save Role</button>       
                        </div>
                    </form>
            </div>
        </Modal>
    )
}

export default EditUserRoleModal;
