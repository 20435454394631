import * as React from 'react';
import { useEffect, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import {Auth} from 'aws-amplify';
import {Amplify, API, graphqlOperation, Storage} from 'aws-amplify';
import styled from 'styled-components';


//import * as queries from "../../../src/graphql/queries";
import { createVendors, updateVendors, createVendorUsers } from "../../../src/graphql/mutations";
import awsconfig from '../../../src/aws-exports';
import "./home.scss";
import { AuthContext } from '../../context/AuthContext';
import { VendorUserContext } from '../../context/VendorUserContext';
import EditVendorModal from '../../components/Vendors/edit-vendor-modal';
import TopNavBar from '../Landing/topNavBar';
import Footer from '../Landing/footer';

const InstructionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const InstructionsItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const InstructionsColorCreated = styled.div`
    padding: 10px;
    background-color: #2A3841;
    color: white;
`;

const InstructionsColorActive = styled.div`
    padding: 10px;
    background-color: #844454;
    color: white;
`;

const InstructionsColorInactive = styled.div`
    padding: 10px;
    background-color: grey;
    color: black;
`;

const InstructionsItemText = styled.div`
    margin-left: 10px;
`

const FirstLoggedInHome = () => {
    const {currentUser, dispatch: authDispatch} = useContext(AuthContext);
    const {getUserVendors, userVendors, dispatch } = useContext(VendorUserContext);
    const navigate = useNavigate();
    const [newVendorModalOpen, setNewVendorModalOpen] = useState(false);

    console.log('currentUser', currentUser)

    Amplify.configure(awsconfig);

    useEffect(() => {
        const fetchVendors = async () => {
            console.log('in fetchVendors currentUser', currentUser);
            if (currentUser.id) {
                try {
                    const userVendors = await getUserVendors(currentUser.id);
                    const parsedUserVendors = userVendors && userVendors.length > 0 ? userVendors : [];
                    dispatch({ type: "SET_USER_VENDORS", payload: parsedUserVendors});
                } catch (err) {
                    console.log('error:' , err);
                }
            }
        };
        fetchVendors();
    }, [currentUser]);


    const handleLogout = async(e) => {
        try {
            await Auth.signOut()
            authDispatch({ type: "LOGOUT", payload: null});
            dispatch({type: "UNSET_USER_VENDORS", payload: null});
            dispatch({type: "UNSET_USER_VENDOR_SELECTED", payload: null})
            navigate("/");
        } catch (err) {
            console.log('error signing out: ', err)
        }
    }

    const createVendor = async (formData, imageData) => {
        console.log('imageData .......', imageData)
        let imageKey;
        let imageUri;
        try {
            const theInput = {
                vendorZip: formData.vendorZip,
                vendorCity: formData.vendorCity,
                vendorEmail: formData.vendorEmail,
                vendorName: formData.vendorName,
                vendorState: formData.vendorState,
                vendorStreet: formData.vendorStreet,
                vendorContactPhone: formData.vendorContactPhone,
                waveDrinklinkFee: false,
                vendorDisplayStatus: "hidden",
                status: "created"
            }

            const addedVendor = await API.graphql(graphqlOperation(createVendors, {input: theInput}))
            // upload image and get referece to url
            
            const newVendor = addedVendor.data.createVendors.id;
            console.log('newVendor', newVendor)
            let vendorUser;
            if (addedVendor) {
                if (imageData){
                    const imgStorageName = `${newVendor}-${new Date().getTime()}.jpg`;
                    const result = await Storage.put(imgStorageName, imageData, {
                        contentType: imageData.type
                    });

                    const updateInput = {
                        id: newVendor,
                        vendorImage:result.key
                    }
                    await API.graphql(graphqlOperation(updateVendors, {input: updateInput}))
                    imageUri = Storage.get(result.key);
                }
                // add user to vendorUsers
                vendorUser = {
                    role: "admin",
                    status: "active",
                    usersID: currentUser.id,
                    vendorsID: newVendor
                }
                await API.graphql(graphqlOperation(createVendorUsers, {input: vendorUser}))
            }

            const vendorData = {
                ...formData,
                ...vendorUser,
                vendorImage: imageKey,
                vendorImageUrl:  imageUri
            }
            dispatch({type: "SET_USER_VENDOR_SELECTED", payload: vendorData})
            navigate("/vendorHome");
        } catch (e) {
            console.log('error creating vendor', e);
            return {}
        }
    }

    const handleSelectVendor = async (vendor) => {
        console.log('vendor .....', vendor);
        vendor.vendorImageUrl = vendor.vendorImage ? await Storage.get(vendor.vendorImage) : null;
        dispatch({type: "SET_USER_VENDOR_SELECTED", payload: vendor})
        navigate("/vendorHome");
    }

    const openNewVendorModal = () => {
        setNewVendorModalOpen(true);
    }

    const handleNewVendorModalClose = () => {
        setNewVendorModalOpen(false);
    }

    const handleNewVendorFormSubmit = (formData, imageData) => {
        if (formData.vendorName === '' || formData.vendorStreet === '' || formData.vendorCity === '' || formData.vendorState === '' || formData.vendorZip === '' || formData.vendorContactPhone === '') {
            return;
        }
        createVendor(formData, imageData);        
    }

    const displayVendors = () => {
        return userVendors.map((vendor, index) => {
            if (!vendor || !vendor.vendorName || vendor.vendorName === '') {
                return null;
            }
            return ( 
                <div className='margin-spacer' key={`spacer-${index}`}>
                <div 
                    key={vendor.id}
                    onClick={() => handleSelectVendor(vendor)}
                    className={vendor.status === 'created' ? 'vendor-select-button-created' : 'vendor-select-button'}
                >
                    {vendor.vendorName} - {vendor.vendorCity}, {vendor.vendorState}, {vendor.vendorZip}
                </div>
            </div>
            )
        })
    }

    return (
        <>
        <TopNavBar />
        <div className="select-vendor-wrapper">
            <div className='select-vendor-title'>Select one of your Vendors:</div>
            <InstructionsWrapper>
                <InstructionsItem key='item-created'>
                    <InstructionsColorCreated></InstructionsColorCreated>
                    <InstructionsItemText>Indicates vendor created but not yet verified by DrinkLink</InstructionsItemText>
                </InstructionsItem>
                <InstructionsItem key='item-active'>
                    <InstructionsColorActive></InstructionsColorActive>
                    <InstructionsItemText>Indicates vendor is active and findable by app</InstructionsItemText>
                </InstructionsItem>
                <InstructionsItem key='item-inactive'>
                    <InstructionsColorInactive></InstructionsColorInactive>
                    <InstructionsItemText>Indicates vendor is inactive and hidden from app</InstructionsItemText>
                </InstructionsItem>
            </InstructionsWrapper>
            {userVendors ? 
                <>
                    {userVendors.length > 0 && 
                    <>
                        <div>{displayVendors()}</div>
                        <div className='margin-spacer'></div>
                        <div>OR</div> 
                        <div className='margin-spacer'></div>
                    </>
                    }
                    <div 
                        onClick={() => openNewVendorModal()}
                        className='vendor-select-button'
                    >
                        Create New Vendor
                    </div>
                    <div 
                        onClick={() => handleLogout()}
                        className='vendor-logout-button'
                    >
                        Logout
                    </div>
                </>
                : 
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="96"
                    visible={true}
                />
            }
            
        </div>
        {newVendorModalOpen &&
            <EditVendorModal
                modalState={newVendorModalOpen}
                handleModalClose={handleNewVendorModalClose}
                handleFormSubmit={handleNewVendorFormSubmit}
                editingVendor={null}
            >

            </EditVendorModal>
        }
        <Footer />
        </>
    )
}

export default FirstLoggedInHome;