import * as React from 'react';
import { useEffect, useContext, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { RotatingLines } from 'react-loader-spinner';
import {Amplify, API, graphqlOperation, Storage} from 'aws-amplify';
import styled from 'styled-components';
import WidgetDrinklinkAdmin from "../../components/widget/WidgetDrinklinkAdmin";
import FeaturedDrinklinkAdmin from "../../components/featured/FeaturedDrinklinkAdmin";
import Chart from "../../components/chart/Chart";


//import * as queries from "../../../src/graphql/queries";
import awsconfig from '../../../src/aws-exports';
import { AuthContext } from '../../context/AuthContext';
import { DrinklinkAdminContext } from '../../context/DrinklinkAdminContext';
import SidebarDrinklinkAdmin from '../../components/sidebar/SidebarDrinklinkAdmin'
import TopNavBar from '../Landing/topNavBar';
import Footer from '../Landing/footer';
import {Spinner} from 'react-bootstrap';

const InstructionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const InstructionsItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const InstructionsColorCreated = styled.div`
    padding: 10px;
    background-color: #2A3841;
    color: white;
`;

const InstructionsColorActive = styled.div`
    padding: 10px;
    background-color: #844454;
    color: white;
`;

const InstructionsColorInactive = styled.div`
    padding: 10px;
    background-color: grey;
    color: black;
`;

const InstructionsItemText = styled.div`
    margin-left: 10px;
`

const Main = () => {
    const {currentUser, dispatch: authDispatch} = useContext(AuthContext);
    const {getTheData, dispatch: adminDispatch} = useContext(DrinklinkAdminContext)
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const [newVendorModalOpen, setNewVendorModalOpen] = useState(false);

    console.log('currentUser', currentUser)

    Amplify.configure(awsconfig);

    

    useEffect(() => {
        const onGetData = async () => {
            await getTheData()
            setIsLoading(false)
        }

        onGetData()
    },[])

    // useEffect(() => {
    //     const fetchVendors = async () => {
    //         console.log('in fetchVendors currentUser', currentUser);
    //         if (currentUser.id) {
    //             try {
    //                 const userVendors = await getUserVendors(currentUser.id);
    //                 const parsedUserVendors = userVendors && userVendors.length > 0 ? userVendors : [];
    //                 dispatch({ type: "SET_USER_VENDORS", payload: parsedUserVendors});
    //             } catch (err) {
    //                 console.log('error:' , err);
    //             }
    //         }
    //     };
    //     fetchVendors();
    // }, [currentUser]);


    // const handleLogout = async(e) => {
    //     try {
    //         await Auth.signOut()
    //         authDispatch({ type: "LOGOUT", payload: null});
    //         dispatch({type: "UNSET_USER_VENDORS", payload: null});
    //         dispatch({type: "UNSET_USER_VENDOR_SELECTED", payload: null})
    //         navigate("/");
    //     } catch (err) {
    //         console.log('error signing out: ', err)
    //     }
    // }

    return (
        <>
        <TopNavBar />
        <div className="home">
        <SidebarDrinklinkAdmin />
        {isLoading ? 
            <Spinner></Spinner>
        :
            <div className="homeContainer">
            <div className="widgets">
                <WidgetDrinklinkAdmin type="user" />
                <WidgetDrinklinkAdmin type="order" />
                <WidgetDrinklinkAdmin type="earning" />
                <WidgetDrinklinkAdmin type="paid-out" />
            </div>
            <div className="charts">
                <FeaturedDrinklinkAdmin />
                <Chart title="Last 6 Months (Revenue)" aspect={2 / 1} />
            </div>
            <div className="listContainer">
                <div className="listTitle">Latest Transactions</div>
                {/*<Table /> */}
            </div>
            </div>
        }
      </div>
        <Footer />
        </>
    )
}

export default Main;