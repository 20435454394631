import * as React from 'react';

import styled from 'styled-components';

const PageWrap = styled.div`
    width: 80%;
    margin: auto;
`;

const PageContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    justify-content: space-evenly;
`;

const ImageWrapper = styled.div`

`;

const SMSTurnOnOffImages = () => {
    return (
        <>
            <PageWrap>
                <PageContainer>
                    <ImageWrapper>
                        <img src={require('../../assets/turnOffText.png')} alt="app edit profile view sms on"/>
                    </ImageWrapper>
                    <ImageWrapper>
                        <img src={require('../../assets/turnOnText.png')} alt="app edit profile view sms off"/>
                    </ImageWrapper>
                </PageContainer>
            </PageWrap>
        </>
    )
};

export default SMSTurnOnOffImages