//import "./list.scss"
import * as React from 'react';
import { useState, useEffect, useContext, useMemo } from "react";
import styled from 'styled-components';
import {Spinner} from 'react-bootstrap';
import SubCategoryBox from './subCategoryBoxComponent'
import NewItemBox from './newItemBoxComponent';
import {API, graphqlOperation} from "aws-amplify";
import { VendorUserContext } from '../../context/VendorUserContext';

import { 
    createSubCategories,
    deleteSubCategories
} from "../../../src/graphql/mutations"

const PageWrap = styled.div`
    margin: 40px 40px;
    display: flex;
    flex-direction: column;
`

const NavWrap = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
`
const NavItem = styled.div`
    cursor: pointer
`

const NavItemBack = styled.div`
    cursor: pointer
`

const NoSubsText = styled.div``

const CatsBoxWrap = styled.div`
    display: flex;
`

const CatsBoxContent = styled.div`
    margin: 40px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`

const CategoryNameTitle = styled.div`
`

const EditSubCategoriesComponent = ({
    categoriesIn,
    cat,
    setCategories,
    setCatSelected
}) => {  
    const { userVendorSelected } = useContext(VendorUserContext);
    
    console.log('cat', cat.subCategories.items)

    const handleEditSubClick = () => {
        //console.log('edit cat', cat)
    }

    const saveNewSubCatToDB = async (mainCatId, subCatName) => {
        console.log('mainCatId', mainCatId);
        console.log('new sub name: ', subCatName)
        const newInput = {
            name: subCatName,
            vendorsID: userVendorSelected.id,
            mainCategoriesID: mainCatId
        }
        const addedMenuItem = await API.graphql(graphqlOperation(createSubCategories, {input: newInput}))
        return addedMenuItem.data
    }

    const handleSaveNewSub = (newSub) => {
        const newSubCatId = saveNewSubCatToDB(cat.id, newSub)
        const theseCategories = [...categoriesIn]
        const foundCategoryIndex = theseCategories.findIndex((ct) => ct.id === cat.id);
        if (foundCategoryIndex > -1) {
            const theSubs = theseCategories[foundCategoryIndex].subCategories.items
            theSubs.push({ subcategoryId: newSubCatId, name: newSub})
        }
        setCategories(theseCategories)
    }

    const handleDeleteSub = async (subId) => {
        try {
            const deleteResult = await API.graphql(graphqlOperation(deleteSubCategories, {input: {id: subId}}))
            if (deleteResult) {
                const theseCategories = [...categoriesIn];
                const foundMain = theseCategories.findIndex((ct) => ct.id === cat.id);
                const theseSubs = theseCategories[foundMain].subCategories.items;
                const filterResult = theseSubs.filter((sub) => subId !== sub.id)
                theseCategories[foundMain].subCategories.items = filterResult
                setCategories(theseCategories)
            }
        } catch (e) {
            console.log('error deleting sub category', e)
        }
    }

    const displaySubCat = (subCat) => {
        return (
            <SubCategoryBox
                subCategory={subCat}
                handleDeleteSub={handleDeleteSub}
            />
        )
    }

    const displaySubCats = () => {
        if (!cat.subCategories?.items || cat.subCategories.items.length < 1) {
            return (
                <>
                    <NewItemBox
                        handleSaveItem={handleSaveNewSub}
                        placeholderText='new sub category'
                        type='sub'
                    />
                    <NoSubsText>This category - {cat.name} has no Subcategories</NoSubsText>
                </>
            )
        }
        return (
            <>
                <NewItemBox
                    handleSaveItem={handleSaveNewSub}
                    placeholderText='new sub category'
                />
                {cat.subCategories.items.map((subcat) => displaySubCat(subcat))}
            </>
        )
    }

    return (
      <PageWrap>
        <NavWrap>
            <NavItemBack onClick={() => setCatSelected('none')}>{`<- Back to Categories`}</NavItemBack>
            <CategoryNameTitle>Category: {cat.name}</CategoryNameTitle>
            {/* <NavItem>Sub Categories</NavItem>
            <NavItem>Sub Category Modifiers</NavItem> */}
        </NavWrap>
        <CatsBoxWrap>
            {cat.subCategories ? 
                <CatsBoxContent>{displaySubCats()}</CatsBoxContent>
                :
                <Spinner></Spinner>
            }
        </CatsBoxWrap>
    </PageWrap>
    )
}

export default EditSubCategoriesComponent