import * as React from 'react';
import styled from 'styled-components';


const InstructionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 60%;
`;

const InstructionsItem = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
`;

const InstructionsItemSmall = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    font-size: 12px;
`;

const InstructionsItemText = styled.div`
    margin-left: 10px;
`

const DeleteAccountRequest = () => {
    return (
        <>
        <div className="select-vendor-wrapper">
            <div className='select-vendor-title'>To delete your Drinklink account:</div>
            <InstructionsWrapper>
                <InstructionsItem>
                    <InstructionsItemText>Email support@drinklink.com to delete your account.  Include in the email that you wish to delete your account along with your phone number you use to login.</InstructionsItemText>
                </InstructionsItem>
                <InstructionsItem>
                    <InstructionsItemText>All data you have submitted including phone number, email, firstname, lastname, username, city, and profile images will be deleted.</InstructionsItemText>
                </InstructionsItem>
                <InstructionsItem>
                    <InstructionsItemText>If you only wish to delete some of your information - for example your name, you can do that within the app itself.  Just click to edit your profile on the home page, remove what you want, and then save.</InstructionsItemText>
                </InstructionsItem>
                <InstructionsItemSmall>
                    <InstructionsItemText>*Note - since you will no longer be identifiable within Drinklink app, any un-redeemed drinks will be forfeited.</InstructionsItemText>
                </InstructionsItemSmall>
            </InstructionsWrapper>
            
        </div>
        </>
    )
}

export default DeleteAccountRequest;