import * as React from 'react';
import { useState, useContext } from 'react';

import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';

import "./login.scss"
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

import {getUsers, listDrinklinkAdmins} from "../../../src/graphql/queries";
import * as mutations from "../../../src/graphql/mutations";
import awsconfig from '../../../src/aws-exports';
import {Amplify} from 'aws-amplify';
import {Auth, API, graphqlOperation} from 'aws-amplify';
import TopNavBar from '../Landing/topNavBar';

Amplify.configure(awsconfig);
//const client = generateClient()

export const Login = () => {
    const [ error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [number, setNumber] = useState();

    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    const isRessetPassword = queryParameters.get('resetPassword');
    const newSignUp = queryParameters.get('newSignUp');

    const { dispatch } = useContext(AuthContext);

    const getUserById = async (data) => {
        try {
            console.log('getUserBy uuid', data.uid)
            const user = await API.graphql(graphqlOperation(getUsers, { id: data.uid }));
            return user.data.getUsers ? user.data.getUsers : null;
        } catch (e) {
            console.log(e);
        }
    }

    const addUser = async (data) => {
        try {
            const newUser = await API.graphql({
                query: mutations.createUsers,
                variables: {
                    input: {
                        email: data.email,
                        id: data.uid,
                    }
                }
            })
            return newUser.data.createUsers;
        } catch (e) {
            console.log('e', e);
        }
    }

    async function getDrinklinkUser(userId) {
        console.log('in getDrinklinkUser', userId)
        try {
            const drinlinkAdmins = await API.graphql(graphqlOperation(listDrinklinkAdmins, {
                limit: 10,
                filter: {usersID: {eq: userId}}
            }))
            console.log('res..........', drinlinkAdmins);
            let dlUser;
            if (drinlinkAdmins.data?.listDrinklinkAdmins?.items?.length > 0){
                dlUser = {
                    role: drinlinkAdmins.data.listDrinklinkAdmins.items[0].role,
                    status: drinlinkAdmins.data.listDrinklinkAdmins.items[0].status
                }
            }
            
            console.log('dlUser', dlUser)
            return dlUser
        } catch (e) {
            console.log('error geting drinklink users', e)
        }
    }
    
    const handleLogin = async(e) => {
        e.preventDefault();
        console.log('number', number);
        let resp;
        try {
            resp = await Auth.signIn({
                username: number,
                password
            });
        } catch (e) {
            console.log('error signing in', e);
            setError(e.message);
        }
        
        console.log('resp', resp)
        if (resp) {
            try {
                let signedInUser = await getUserById({uid: resp.attributes.sub, email});
                console.log('signedInUser', signedInUser)
                if (!signedInUser) {
                    // Is new registration - add to our DB
                    console.log('is new signup, add user')
                    const newUser = {
                        uid: resp.attributes.sub,
                        email: resp.attributes.email,
                    }
                    const addUserRes = await addUser(newUser);
                    signedInUser = addUserRes; //await getUserById(signedInUser.user.uid);
                } else {
                    const drinklinkUser = await getDrinklinkUser(signedInUser.id)
                    signedInUser.drinklinkUser = drinklinkUser
                }
                dispatch({ type: "LOGIN", payload: signedInUser});
                navigate("/");
            } catch (err) {
                setError(err)
            }
            
        }
        return resp;
    }

    return (
        <>
            <TopNavBar />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {isRessetPassword && <Alert severity="success">Password Successfully Reset</Alert>}
                    {newSignUp && <Alert severity="success">Account Successfully Created</Alert>}
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {error && <Alert severity="error">{error}.</Alert>}
                    <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                        {/* <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={e=>setEmail(e.target.value)}
                        /> */}
                        <PhoneInput
                            addInternationalOption={false}
                            defaultCountry="US"
                            value={number}
                            onChange={setNumber}
                            placeholder="Enter Phone Number"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={e=>setPassword(e.target.value)}
                        />
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/resetPassword" variant="body2">
                                Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/signUp" variant="body2">
                                {"New? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>
    )
};

export default Login;