import * as React from 'react';

import Home from "./pages/home/Home";
import LandingPage from './pages/Landing/landing';
import Login from "./pages/login/Login";
import ResetPassword from "./pages/login/ResetPassword";
import SignUp from './pages/login/SignUp';
//import LoginPhone from './pages/login/LoginPhone';
import UserList from "./pages/list/UserList";
import DrinklinkAdminUserList from './pages/list/DrinklinkAdminUserList';
import DrinklinkAdminVendorsList from './pages/list/DrinklinkAdminVendorsList';
import VendorsOwed from './pages/DrinklinkAdminPages/vendorsOwed';
import VendorInfo from "./pages/vendorInfo/VendorInfo";
import MenuItemList from "./pages/list/MenuItemList";
import NotFound from './components/notFound/NotFound';
import FirstLoggedInHome from './pages/home/FirstLoggedInHome';
import PrivacyPolicy from './pages/PrivacyPolicy/privacyPolicy';
import DeleteAccountRequest from './pages/DeleteAccountRequest/deleteAccountRequest';
import AlcoholSupportResources from './pages/SupportResources/alcoholSuportResources';
import SMSTurnOnOffImages from './pages/CompliancePages/SMSTurnOnOffImages';
import Main from './pages/DrinklinkAdminPages/main';
//import Single from "./pages/single/Single";
//import New from "./pages/new/New";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {userInputs, userTitle} from "./formSource";
import "./style/dark.scss";
import "./style/main.scss";
import {useContext} from "react";
import {DarkModeContext} from "./context/darkModeContext";
import {AuthContext} from './context/AuthContext';
import DrinksList from './pages/list/DrinksList';
import TermsOfService from './pages/TermsOfService/termsOfService';
import MyAccount from './pages/home/MyAccount';
import EditCategories from './pages/VendorAdminPages/EditCategories';

function App() {
    const {darkMode} = useContext(DarkModeContext);
    const {currentUser} = useContext(AuthContext);

    const RequireAuth = ({children}) => {
        return currentUser ? (children) : <Navigate to="/login" />
    }

    return (
        <div className={darkMode ? "app dark" : "app"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        {/**/}
                        <Route index element={<LandingPage />} />
                        <Route path="vendorHome" element={<Home />} />
                        <Route path="login" element={<Login />} />
                        <Route path="resetPassword" element={<ResetPassword />} />
                        <Route path="signUp" element={<SignUp />} />
                        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
                        <Route path="tos" element={<TermsOfService />} />
                        <Route path="sms-turn-on-off-images" element={<SMSTurnOnOffImages />} />
                        <Route path="deletemyaccount" element={<DeleteAccountRequest />} />
                        <Route path="alcoholsupportservices" element={<AlcoholSupportResources />} />
                        <Route path="selectVendor" element={
                            <RequireAuth>
                                <FirstLoggedInHome />
                            </RequireAuth>
                        } />
                        <Route path="users">
                            <Route index element={
                                <RequireAuth>
                                    <UserList />
                                </RequireAuth>
                            } />
                            {/*<Route path=":userId" element={<Single />} /> */}
                            {/* <Route
                                path="new"
                                element={
                                    <RequireAuth>
                                        <New inputs={userInputs} title={userTitle} />
                                    </RequireAuth>
                                }
                            /> */}
                        </Route>
                        <Route path="menuItems">
                            <Route index element={
                                <RequireAuth>
                                    <MenuItemList />
                                </RequireAuth>
                            } />
                            {/*<Route path=":menuItemId" element={<Single />} /> */}
                        </Route>
                        <Route path="editCats">
                            <Route index element={
                                <RequireAuth>
                                    <EditCategories />
                                </RequireAuth>
                            } />
                            {/*<Route path=":menuItemId" element={<Single />} /> */}
                        </Route>
                        <Route path="drinks">
                            <Route index element={
                                <RequireAuth>
                                    <DrinksList />
                                </RequireAuth>
                            } />
                        </Route>
                        <Route path="vendorInfo">
                            <Route index element={
                                <RequireAuth>
                                    <VendorInfo />
                                </RequireAuth>
                            } />
                        </Route>
                        <Route path="drinklinkAdmin">
                            <Route index element={
                                <RequireAuth>
                                    <Main />
                                </RequireAuth>
                            } />
                        </Route>
                        <Route path="drinklinkAdmin-users">
                            <Route index element={
                                <RequireAuth>
                                    <DrinklinkAdminUserList />
                                </RequireAuth>
                            } />
                        </Route>
                        <Route path="drinklinkAdmin-vendors">
                            <Route index element={
                                <RequireAuth>
                                    <DrinklinkAdminVendorsList />
                                </RequireAuth>
                            } />
                        </Route>
                        <Route path="drinklinkAdmin-vendorsOwed">
                            <Route index element={
                                <RequireAuth>
                                    <VendorsOwed />
                                </RequireAuth>
                            } />
                        </Route>
                        <Route path="my-account">
                            <Route index element={
                                <RequireAuth>
                                    <MyAccount />
                                </RequireAuth>
                            } />
                        </Route>
                    </Route>
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
