import "./list.scss";
import * as React from 'react';
import SidebarDrinklinkAdmin from "../../components/sidebar/SidebarDrinklinkAdmin";
import UsersDatatable from "../../components/Users/users-datatable";
import DrinklinkAdminUsersDatatable from "../../components/Users/drinklink-admin-users-datatable";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
import VendorNameBlock from "../home/VendorNameBlock";

const DrinklinkAdminUserList = () => {

  return (
    <>
      <TopNavBar />
        <div className="list">
            <SidebarDrinklinkAdmin/>
            <div className="listContainer">
              <DrinklinkAdminUsersDatatable />
            </div>
        </div>
      <Footer />
    </>
  )
}

export default DrinklinkAdminUserList