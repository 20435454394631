import * as React from 'react';
import {createContext, useReducer} from 'react';
import {Amplify, API, graphqlOperation, Storage} from 'aws-amplify';

import DrinklinkAdminReducer from './DrinklinkAdminReducer';

const INITIAL_STATE = {
    usersTotal: null,
    ordersTotal: null,
    earningsTotal: null,
    payoutsTotal: null,
    revenueTotal: null
}

export const DrinklinkAdminContext = createContext(INITIAL_STATE);

export const DrinklinkAdminContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(DrinklinkAdminReducer, INITIAL_STATE);

    const getUsersTotal = () => {

    }
    const getTheData = () => {
        // get total users
        // get total orders,
        // get total earnings,
        // get total payouts
        // get total revenue
    }

    return (
        <DrinklinkAdminContext.Provider value={({
            usersTotal: state.usersTotal,
            ordersTotal: state.ordersTotal,
            earningsTotal: state.earningsTotal,
            payoutsTotal: state.payoutsTotal,
            revenueTotal: state.revenueTotal,
            dispatch,
            getTheData
        })}>
            {children}
        </DrinklinkAdminContext.Provider>
    )
}