import * as React from 'react';
import styled from 'styled-components';


const TOSWrapper = styled.div`
    width: 80%;
    margin: auto;
`;

const SeparatorLine = styled.div`
    border-top: solid 1px grey;
    margin: 20px 0px;
`;

const TOSTitle = styled.div`
    font-size: 24px;
    margin-top: 20px;
`;

const TOSSectionBody = styled.div`
    font-size: 18px;
    margin-bottom: 15px;
`;

const SupportLink = styled.div`
`;


const AlcoholSupportResources = () => {
    return (
        <>
            <TOSWrapper>
                <TOSTitle>
                    Alcohol Support Resource Center
                </TOSTitle>
                <TOSSectionBody>
                    We aim to foster a responsible drinking culture and ensure you have access to support resources should you or a loved one ever need them.
                </TOSSectionBody>
                <TOSSectionBody>
                    You can find a list of organizations and resources centered around education, prevention, treatment, and recovery below. If you are interested in disabling your Drizly account or the account of a loved one, we are here to help. Please reach out to us at support@drinklink.com.
                </TOSSectionBody>
                <TOSSectionBody>
                    As always, take care of yourself and each other. The 988 Suicide & Crisis Lifeline (988 Lifeline) is available for anyone who is struggling with substance use or is feeling depressed, anxious, or upset. The 988 Lifeline - funded by SAMHSA and with Vibrant as the nonprofit administrator - can be reached 24/7 by calling or texting 988 or through the chat service. You can also contact SAMHSA’s National Helpline at 1-800-662-HELP (4357) for treatment referrals.
                </TOSSectionBody>
                <TOSSectionBody>
                    We are here for you, we care for you. Thank you for using Drinklink to send cheer.
                </TOSSectionBody>
                <TOSTitle>
                    April is Alcohol Awareness Month
                </TOSTitle>
                <TOSSectionBody>
                    Alcohol Awareness Month serves to increase awareness and understanding of the causes and treatment of alcohol abuse and to destigmatize the disease of addiction. To learn more, please visit any of the resources below.
                </TOSSectionBody>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Learn More | Vibrant Emotional Health
                </TOSTitle>
                <TOSSectionBody>
                    This month, we will be partnering with Vibrant Emotional Health (Vibrant), a national mental health nonprofit that believes that everyone can achieve emotional well-being with dignity and respect. Vibrant has been working in the mental health field for over 50 years. Learn more about Vibrant's programs here.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.vibrant.org/what-we-do/">Visit Vibrant</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Learn More | National Institute On Alcohol Abuse And Alcoholism
                </TOSTitle>
                <TOSSectionBody>
                The mission of the National Institute on Alcohol Abuse and Alcoholism (NIAAA) is to generate and disseminate fundamental knowledge about the effects of alcohol on health and well-being, and apply that knowledge to improve diagnosis, prevention and treatment of alcohol-related problems.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.niaaa.nih.gov/">VISIT NIAAA</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Helping A Loved One | Alcohol.Org
                </TOSTitle>
                <TOSSectionBody>
                    Alcohol.org (a subsidiary of American Addiction Centers) is a comprehensive resource for research based information on the nature of alcohol abuse and addiction. They offer helpful resources on how to help an alcoholic by identifying the risk factors, how alcoholism is diagnosed, and what effective treatment looks like.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.alcohol.org/helping-an-alcoholic/Visit">Alcohol.Org</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Prevention Resources | Substance Abuse And Mental Health Services Administration
                </TOSTitle>
                <TOSSectionBody>
                    Prevention and early intervention strategies can reduce the impact of substance use and mental disorders. Substance Abuse and Mental Health Services Administration’s (SAMHSA) prevention and early intervention efforts promote evidence-based decision-making. They offer a variety of substance use disorder prevention tools and resources.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.samhsa.gov/find-help/prevention">VISIT SAMHSA</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Get support
                </TOSTitle>
                <TOSSectionBody>
                    If you or a loved one may be looking for support, you are not alone. The following organizations can help. Please note that Drizly is not directly affiliated with any of the organizations below. If you are experiencing a life threatening emergency, please dial 911.
                </TOSSectionBody>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Shatterproof
                </TOSTitle>
                <TOSSectionBody>
                    Shatterproof is a national nonprofit that believes no one should suffer or die from a substance use disorder, a preventable and treatable disease. Shatterproof provides trusted guidance to communities, removes systemic and social barriers to recovery, and mobilizes the country to advocate for change and to end addiction stigma. As Founder/CEO, Gary Mendell, puts it, “We don't wait for change, we create it.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.shatterproof.org/">Learn More</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    SMART Recovery
                </TOSTitle>
                <TOSSectionBody>
                    A global community of free mutual-support groups. At meetings, participants help one another resolve problems with any addiction (to drugs or alcohol or to activities such as gambling or over-eating). Participants find and develop the power within themselves to change and lead fulfilling and balanced lives.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.smartrecovery.org/">Learn More About SMART Recovery</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Alcoholics Anonymous
                </TOSTitle>
                <TOSSectionBody>
                    Alcoholics Anonymous is an international fellowship of people who have had a drinking problem. It is nonprofessional, self-supporting, multiracial, apolitical, and available almost everywhere. There are no age or education requirements. Membership is open to anyone who wants to do something about their drinking problem.
                </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.aa.org/pages/en_US/need-help-with-a-drinking-problem">Get Help</a>
                </SupportLink>
                <SeparatorLine></SeparatorLine>
                <TOSTitle>
                    Request to delete a Drinklink account 
                </TOSTitle>
                <TOSSectionBody>
                    If you are interested in deleting your Drinklink account, we are here to help you. Please reach out to us at support@drinklink.com and we’ll get back to you as quickly as possible.
                    </TOSSectionBody>
                <SupportLink>
                    <a href="https://www.drinklink.com/deletemyaccount">See details</a>
                </SupportLink>
            </TOSWrapper>
        </>
    )
}

export default AlcoholSupportResources;