const DrinklinkAdminReducer = (state, action) => {
    switch (action.type) {
        case "SET_DATA_TOTALS": {
            return {
                usersTotal: action.payload.usersTotal,
                ordersTotal: action.payload.ordersTotal,
                earningsTotal: action.payload.earningsTotal,
                payoutsTotal: action.payload.payoutsTotal,
                revenueTotal: action.payload.revenueTotal,
            }
        }
        case "UNSET_DATA_TOTALS": {
            return {
                usersTotal: null,
                ordersTotal: null,
                earningsTotal: null,
                payoutsTotal: null,
                revenueTotal: null,
            }
        }
        default:
            return state;
    }
}

export default DrinklinkAdminReducer