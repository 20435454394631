import * as React from 'react';

export const userLinks = {
    newUser: "/users/new",
    newUserText: "Add New User",
    editUserLink: "/users/test",
    userTitleText: "Current Users"
};

export const userColumns = [
    {
        field: "user",
        headerName: "User",
        width: 230,
        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    {/* <img className="cellImg" src={params.row.avatarImage} alt="avatar" /> */}
                    {params.row.users.fName} {params.row.users.lName}
                </div>
            );
        },
    },
    {
        field: "email",
        headerName: "Email",
        width: 230,
        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    {/* <img className="cellImg" src={params.row.avatarImage} alt="avatar" /> */}
                    {params.row.users.email}
                </div>
            );
        },
    },
    {
        field: "role",
        headerName: "Role",
        width: 160
    },
    {
        field: "status",
        headerName: "Status",
        width: 160,
        renderCell: (params) => {
            return (
                <div className={`cellWithStatus ${params.row.status}`}>
                    {params.row.status}
                </div>
            );
        },
    },
];