import "./list.scss"
import * as React from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import MenuItemDataTable from "../../components/MenuItems/menu-items-datatable";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
import VendorNameBlock from "../home/VendorNameBlock";

const MenuItemList = () => {
    
    return (
        <>
            <TopNavBar />
            <div className="list">
                <Sidebar/>
                <div className="listContainer">
                    <VendorNameBlock />
                    <MenuItemDataTable />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MenuItemList