import * as React from 'react';
import { useState, useCallback, useEffect } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDropzone } from 'react-dropzone';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select'
import styled from 'styled-components';

import "./edit-vendor-modal.scss";
import { stateData } from '../../utils/state-data';
import { isValidEmail } from '../../utils/utils';
import usePhoneNumber from '../../hooks/usePhoneNumber';

// created: 
// drinklinkStatus: "member"
// id: "O1GknjR0PIkTcI2h84lM"
// lastAccess: ""
// role: "admin"
// status: "active"
// userId: "tTVqNDGRt2SOWi1CLH924hejGEt1"
// vendId: "O1GknjR0PIkTcI2h84lM"
// vendorCity: "Portland"
// vendorContactPhone: "503-286-8788"
// vendorImage: "https://firebasestorage.googleapis.com/v0/b/drinklink-bcee9.appspot.com/o/76Oo2rpytcjdf4PnFsUM-1674418874076.jpg?alt=media&token=df2d9a65-6722-460a-adc7-61e3bb173945"
// vendorName: "N. Portland Eagles"
// vendorState: "OR"
// vendorStreet: "7611 N Exeter Ave"
// vendorType: "club"
// vendorZip: "97203"

const FormHeader = styled.div`
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: bold;
    text-align: left;
`;
const FormRequired = styled.div`
    width: 50%;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: left;
`;

const ErrorTextWrapper = styled.div`
    color: red;
    font-size: 14px;
    font-weight: normal;
`;

const VendorImageSelectSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const EditVendorModal = ({modalState, handleModalClose, handleFormSubmit, editingVendor}) => {
    const {
        phoneNumber,
        cleanPhoneNumber,
        toOriginal,
        onNumberChange,
    } = usePhoneNumber(editingVendor?.vendorContactPhone ? editingVendor.vendorContactPhone : '')
    const [formData, setFormData] = useState({
        vendorName: editingVendor?.vendorName ? editingVendor.vendorName : '',
        vendorStreet: editingVendor?.vendorStreet ? editingVendor.vendorStreet : '',
        vendorCity: editingVendor?.vendorCity ? editingVendor.vendorCity : '',
        vendorZip: editingVendor?.vendorZip ? editingVendor.vendorZip : '',
        vendorEmail: editingVendor?.vendorEmail ? editingVendor.vendorEmail : '',
        vendorState: editingVendor?.vendorState ? editingVendor.vendorState : null,
       // vendorContactPhone: editingVendor?.vendorContactPhone ? editingVendor.vendorContactPhone : '',
        vendorImage: editingVendor?.vendorImage ? editingVendor.vendorImage : null,
    });
    const [isComplete, setIsComplete] = useState(false);
    const [theImage, setTheImage] = useState(null);
    const [theState, setTheState] = useState(editingVendor?.vendorState ? editingVendor?.vendorState : null);
    //const [number, setNumber] = useState(editingVendor?.vendorContactPhone ? editingVendor.vendorContactPhone : '');
    const [errorMessage, setErrorMessage] = useState([]);
    const [emailError, setEmailError] = useState(null);
    const [phoneError, setPhoneError] = useState(null);

    const onDrop = useCallback(files => {
        setTheImage(
            Object.assign(files[0], {
                preview: URL.createObjectURL(files[0]),
            })
        )
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        accept: 'image/png, image/jpeg, image/jpg, image/gif, image/avif, image/bmp, image/vnd.microsoft.icon, image/tiff',
    });

    useEffect(() => {
        setErrorMessage([]);
        if (
            formData.vendorName !== '' 
            && formData.vendorStreet && formData.vendorStreet !== '' 
            && formData.vendorCity && formData.vendorCity !== '' 
            && theState && theState !== '' 
            && formData.vendorZip && formData.vendorZip !== '' 
            && formData.vendorEmail && formData.vendorEmail !== ''
            && formData.vendorState && formData.vendorState !== ''
            && phoneNumber && phoneNumber !== '') {
            setIsComplete(true);
        }
    }, [formData, theState, phoneNumber])

    const handleSaveVendor = () => {
        if (!isValidEmail(formData.vendorEmail)) {
            return;
        }
        const number = toOriginal(phoneNumber);
        if (number.length !== 10) {
            setPhoneError('Phone number is not valid');
            return;
        }
    
        const sendData = {
            ...formData,
            vendorContactPhone: number,
            vendorState: theState
        }
        handleFormSubmit(sendData, theImage)
    }

    const handleNumberChange = (event) => {
        const phone = event.target.value;
        setPhoneError(null);
        onNumberChange(phone);
    }

    const handleInputChange = (event) => {
        const value = event.target.value;
        if (event.target.name === 'vendorEmail' && !isValidEmail(value)) {
            setEmailError('Email is not valid');
        } else {
            setEmailError(null)
        } 
        
        setFormData({
            ...formData,
            [event.target.name] : value
        })
    }

    const handleStateChange = (selectedOption) => {
        setTheState(selectedOption.value);
        setFormData({
            ...formData,
            vendorState: selectedOption.value
        })
    }

    return (
        <Modal
            open={modalState}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='edit-vendor-wrapper'>
                <FormHeader>Fill out new vendor information</FormHeader>
                <FormRequired>** All Fields are required except image</FormRequired>
                <form>
                    <div className="formInput" key="vendorName">
                        <label>Name</label>
                        <input 
                            type='text' 
                            placeholder="name"
                            name='vendorName'
                            value={formData.vendorName}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key="vendorEmail">
                        <label>Contact Email</label>
                        <input 
                            type='text' 
                            placeholder="email"
                            name='vendorEmail'
                            value={formData.vendorEmail}
                            onChange={handleInputChange}
                        />
                        {emailError && <ErrorTextWrapper>{emailError}</ErrorTextWrapper>}
                    </div>
                    <div className="formInput" key="vendorStreet">
                        <label>Street</label>
                        <input 
                            type='text' 
                            placeholder="123 some st"
                            name='vendorStreet'
                            value={formData.vendorStreet}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key="vendorCity">
                        <label>City</label>
                        <input 
                            type='text' 
                            placeholder="city"
                            name='vendorCity'
                            value={formData.vendorCity}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="formInput" key="vendorState">
                        <label>State {formData.vendorState}</label>
                        <Select 
                            options={stateData} 
                            name="vendorState"
                            //value={stateData.find(opt => opt.value.toLowerCase() === formData.vendorState?.toLowerCase())}
                            onChange={handleStateChange}
                            defaultValue={stateData.find(opt => opt.value === formData.vendorState)}
                        />
                    </div>
                    <div className="formInput" key="vendorZip">
                        <label>Vendor Zip</label>
                        <input 
                            type='text' 
                            placeholder="12345"
                            name='vendorZip'
                            value={formData.vendorZip}
                            onChange={handleInputChange}
                        />
                    </div>
                    
                    <div className="formInput" key="vendorContactPhone">
                        <label>Contact Phone</label>
                        <input
                            type='text'
                            value={phoneNumber}
                            onChange={handleNumberChange}
                            placeholder="Enter Phone Number"
                        />
                        {phoneError && <ErrorTextWrapper>{phoneError}</ErrorTextWrapper>}
                    </div>
                    <VendorImageSelectSection>
                    {formData.vendorImage && 
                        <div>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Current vendor image:
                            </Typography>
                            <img src={formData.vendorImageUrl} style={{width: "200px"}} alt=""/>
                        </div>
                    }
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{marginTop: '20px'}}>
                        {formData.vendorImage ? 'Upload new image to change:' : 'Upload your vendor image:'}
                    </Typography>
                    <div
                        {...getRootProps({
                        className: `dropzone 
                        ${isDragAccept && 'dropzoneAccept'} 
                        ${isDragReject && 'dropzoneReject'}`,
                        })}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop the file here ...</p>
                        ) : (
                            <p>Drag 'n' drop file here, or click to select file</p>
                        )}
                    </div>
                    {theImage && <div>
                        <img src={theImage.preview} style={{width: "200px"}} alt=""/>
                    </div>}
                    {errorMessage.length > 0 && 
                        <div className='edit-modal-error-message-wrap'>
                            {errorMessage.map(mes => <div className='edit-message-error-message'>Error: {mes}</div>)}
                        </div>
                    }
                    </VendorImageSelectSection>
                    <div className='edit-modal-button-row'>
                        <button type="button" onClick={handleModalClose} >Cancel</button>
                        <button type='button' disable={(!isComplete).toString()} className={!isComplete ? "disabled" : null} onClick={() => handleSaveVendor()}>Save Vendor</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default EditVendorModal;
