import "./list.scss";
import * as React from 'react';
import Sidebar from "../../components/sidebar/Sidebar";
import UsersDatatable from "../../components/Users/users-datatable";
import TopNavBar from "../Landing/topNavBar";
import Footer from "../Landing/footer";
import VendorNameBlock from "../home/VendorNameBlock";

const UserList = () => {

  return (
    <>
      <TopNavBar />
        <div className="list">
            <Sidebar/>
            <div className="listContainer">
              <VendorNameBlock />
              <UsersDatatable />
            </div>
        </div>
      <Footer />
    </>
  )
}

export default UserList